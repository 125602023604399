import {
  NULL_ADDRESS,
  TOTAL_WINS_TO,
  NULL_MOVE,
} from 'shared/constants/constants';
import {
  CloserEnum,
  MovesEnum,
  RoomStatusEnum,
  RoundOutcome,
  RoundStagesEnum,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { PlayerType } from 'shared/types/app-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface State {
  playerA: PlayerType;
  playerB: PlayerType;
  bet: number;
  roomStatus: RoomStatusEnum;
  winsTo: bigint;
  roomBalance: bigint;
  isGameSearching: boolean;
  closerRoom: { closer: CloserEnum; deadline: bigint };
  refetchRoom: () => Promise<any>;
  fromRoomId: bigint;
  lastRoomIdCounter: bigint;
  roomsDataIsFetching: boolean;
  roundCounter: number;
  winnerStatus: WinnerStatusEnum;
  roundStage: RoundStagesEnum;
  roundMoves: {
    playerA: { encrMove: `0x${string}`; move: MovesEnum };
    playerB: { encrMove: `0x${string}`; move: MovesEnum };
  };
  amIPlayerA: boolean;
  someoneElseGame: boolean;
  joinRoomId: bigint | null;
  refetchLastRoomId: () => Promise<any>;
  lastRoundOutcome: RoundOutcome;
  roomExpire: boolean;
  showHash: boolean;
}

interface Actions {
  setIsGameSearching: (flag: boolean) => void;
  setPlayerA: (playerA: PlayerType) => void;
  setPlayerB: (playerB: PlayerType) => void;
  setBet: (betTotal: number) => void;
  setWinsTo: (winsTo: bigint) => void;
  setRoomBalance: (currency: string) => void;
  setRefetchRoom: (refetch: () => Promise<any>) => void;
  setRoom: (arg0: {
    playerA: PlayerType;
    playerB: PlayerType;
    bet: number;
    roomStatus: RoomStatusEnum;
    roomBalance: number;
    winsTo: bigint;
    roundCounter: number;
    winnerStatus: WinnerStatusEnum;
    closerRoom: { closer: CloserEnum; deadline: bigint };
    amIPlayerA: boolean;
    someoneElseGame: boolean;
  }) => void;
  setPlayerAAddress: (address: `0x${string}`) => void;
  resetStore: () => void;
  setFromRoomId: (fromRoomId: bigint) => void;
  setLastRoomIdCounter: (lastRoomIdCounter: bigint) => void;
  setRoomsDataIsFetching: (flag: boolean) => void;
  setRoundStage: (roundStage: RoundStagesEnum) => void;
  setRoundMoves: (roundMoves: {
    playerA: { encrMove: `0x${string}`; move: MovesEnum };
    playerB: { encrMove: `0x${string}`; move: MovesEnum };
  }) => void;
  resetRoom: (address: `0x${string}`) => void;
  setJoinRoomId: (joinRoomId: bigint | null) => void;
  setRefetchLastRoomId: (refetch: () => Promise<any>) => void;
  setLastRoundOutcome: (lastRoundOutcome: RoundOutcome) => void;
  setRoomExpire: (flag: boolean) => void;
  setShowHash: (flag: boolean) => void;
}

const initialState: State = {
  refetchRoom: () => new Promise(() => {}),
  isGameSearching: false,
  playerA: {
    address: NULL_ADDRESS,
    wins: 0n,
  },
  playerB: {
    address: NULL_ADDRESS,
    wins: 0n,
  },
  bet: 0,
  roomBalance: 0n,
  roomStatus: RoomStatusEnum.None,
  winsTo: TOTAL_WINS_TO,
  closerRoom: { closer: CloserEnum.None, deadline: 0n },
  fromRoomId: 0n,
  lastRoomIdCounter: 0n,
  roomsDataIsFetching: true,
  roundCounter: 1,
  winnerStatus: WinnerStatusEnum.None,
  roundStage: RoundStagesEnum.None,
  roundMoves: {
    playerA: { encrMove: NULL_MOVE, move: MovesEnum.None },
    playerB: { encrMove: NULL_MOVE, move: MovesEnum.None },
  },
  amIPlayerA: true,
  someoneElseGame: false,
  joinRoomId: null,
  refetchLastRoomId: () => new Promise(() => {}),
  lastRoundOutcome: RoundOutcome.None,
  roomExpire: false,
  showHash: false,
};

export const useRoomStore = create<State & Actions>()(
  immer(
    devtools((set) => ({
      ...initialState,
      setPlayerA: (playerA) =>
        set(() => ({
          playerA,
        })),
      setPlayerB: (playerB) =>
        set(() => ({
          playerB,
        })),
      setBet: (bet) =>
        set(() => ({
          bet,
        })),
      setWinsTo: (winsTo) =>
        set(() => ({
          winsTo,
        })),
      setRoomBalance: (roomBalance) =>
        set(() => ({
          roomBalance,
        })),
      setIsGameSearching: (isGameSearching) =>
        set(() => ({
          isGameSearching,
        })),
      setRefetchRoom: (refetchRoom) =>
        set(() => ({
          refetchRoom,
        })),
      setRoom: (arg0) =>
        set(() => ({
          ...arg0,
        })),
      setPlayerAAddress: (address) =>
        set((state) => {
          state.playerA.address = address;
        }),
      resetStore: () =>
        set((state) => ({
          ...initialState,
          playerA: {
            ...initialState.playerA,
            address: state.playerA.address,
          },
        })),
      setFromRoomId: (fromRoomId) =>
        set(() => ({
          fromRoomId,
        })),
      setLastRoomIdCounter: (lastRoomIdCounter) =>
        set(() => ({
          lastRoomIdCounter,
        })),

      setRoomsDataIsFetching: (roomsDataIsFetching) =>
        set(() => ({
          roomsDataIsFetching,
        })),
      setRoundStage: (roundStage) =>
        set(() => ({
          roundStage,
        })),
      setRoundMoves: (roundMoves) =>
        set(() => ({
          roundMoves,
        })),
      resetRoom: (address: `0x${string}`) =>
        set(() => ({
          ...initialState,
          playerA: {
            ...initialState.playerA,
            address,
          },
        })),
      setJoinRoomId: (joinRoomId) =>
        set(() => ({
          joinRoomId,
        })),
      setRefetchLastRoomId: (refetchLastRoomId) =>
        set(() => ({
          refetchLastRoomId,
        })),
      setLastRoundOutcome: (lastRoundOutcome) =>
        set(() => ({
          lastRoundOutcome,
        })),
      setRoomExpire: (flag) =>
        set(() => ({
          roomExpire: flag,
        })),
      setShowHash: (flag) =>
        set(() => ({
          showHash: flag,
        })),
    }))
  )
);
