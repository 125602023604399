import POLYGON_AMOY_JSON from './old/polygon-amoy-tokens.json';
import BASE_SEPOLIA_JSON from './old/base-sepolia-tokens.json';
import ARBITRUM_JSON from './arbitrum-tokens.json';
import BASE_JSON from './base-tokens.json';
import ETHEREUM_JSON from './ethereum-tokens.json';
import POLYGON_JSON from './polygon-tokens.json';

export const POLYGON_AMOY_TOKENS = POLYGON_AMOY_JSON;
export const BASE_SEPOLIA_TOKENS = BASE_SEPOLIA_JSON;

export const ARBITRUM_TOKENS = ARBITRUM_JSON;
export const BASE_TOKENS = BASE_JSON;
export const ETHEREUM_TOKENS = ETHEREUM_JSON;
export const POLYGON_TOKENS = POLYGON_JSON;
