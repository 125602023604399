import React from 'react';
import LoaderHoc from 'shared/components/Loaders/LoaderHoc/LoaderHoc';
import './MintingLoader.scss';
import polygonBlue from 'assets/animation/blocks/polygon-blue.png';

interface Props {
  messages?: string[];
  className?: string;
  hash?: `0x${string}` | null;
  selector?: string;
}

const MintingLoader = ({ messages, className, hash, selector }: Props) => {
  return (
    <LoaderHoc
      messages={messages}
      className={className}
      hash={hash}
      selector={selector}
    >
      <div className="flex center">
        <div className="polygons-container">
          <img src={polygonBlue} alt="polygon blue" className="polygon" />
          <img src={polygonBlue} alt="polygon blue" className="polygon" />
          <img src={polygonBlue} alt="polygon blue" className="polygon" />
        </div>
      </div>
    </LoaderHoc>
  );
};

export default MintingLoader;
