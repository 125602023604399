import React from 'react';
import './SocialMediaBtn.scss';

interface Props {
  onClick: () => void;
  svg: React.ReactNode;
}

const SocialMediaBtn = ({onClick, svg}: Props) => {
  return (
    <button className='sm:w-[76px] sm:h-[76px] w-[56px] h-[56px] flex justify-center items-center rounded-[10px] social-media-btn transform transition-transform hover:scale-110' onClick={onClick}>
      <div className='w-[51px] h-[51px] sm:w-[71px] sm:h-[71px] flex justify-center items-center rounded-[10px]  bg-media-btn'>{svg}</div>
    </button>
  );
};

export default SocialMediaBtn;

