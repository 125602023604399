import React from 'react';
import BurgerIcon from 'assets/burger.svg?react';
import Overlay from 'shared/components/Overlay/Overlay';
import './BurgerMenu.scss';

interface Props {
  className?: string;
}

const BurgerMenu = ({ className }: Props) => {
  const [open, setOpen] = React.useState(false);
  const clickHandler = () => {
    setOpen(!open);
  };
  return (
    <>
      <button
        className={`rounded-[8px] burger-btn flex-grow-0 ${className ? className : ''}`}
        onClick={clickHandler}
      >
        <div className="rounded-[8px] text-white m-[1px] p-[8px] burger-btn__inner font-medium text-[10px]">
          <BurgerIcon className="w-[13px] h-[11px]" />
        </div>
      </button>
      <Overlay open={open} setOpen={setOpen} />
    </>
  );
};

export default BurgerMenu;
