import { useRoomStore } from 'shared/store/roomStore';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useEffect } from 'react';

const useCreateJoinRoomEvents = () => {
  const { activeRoomId } = useParams();
  const { address } = useAccount();
  const { setPlayerAAddress } = useRoomStore();

  useEffect(() => {
    if (!activeRoomId) {
      setPlayerAAddress(address?.toLowerCase() as `0x${string}`);
    }
  }, [activeRoomId]);
};

export default useCreateJoinRoomEvents;
