import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Modal from 'shared/components/Modal/Modal';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import DigitalClock from 'shared/components/DigitalClock/DigitalClock';
import { useTimersStore } from 'shared/store/timersStore';
import { useWaitYourMoveModalText } from './useWaitYourMoveModalText';
import { TIME_BEFORE_SHOW_TIMER_IN_MODALS } from 'shared/constants/timers';
import { Timer } from 'shared/constants/types';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { epochToLocalUTC } from 'shared/utils/time';
import { useRoomStore } from 'shared/store/roomStore';
import { createPortal } from 'react-dom';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useCloseUnusedRoom } from 'modules/Room/RoomModals/hooks/useCloseUnusedRoom';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import { technicalDefeatMessages } from 'shared/constants/text-loaders';
import { RoundOutcome } from 'shared/constants/shared-enums';
import useMediaQuery from 'shared/hooks/useMediaQuery';

const WaitYourMoveModal = forwardRef((props, ref) => {
  const { waitYourMoveShowTimer, setWaitYourMoveShowTimer } = useTimersStore();
  const { playMoveModalState } = useModalStore();
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const timeOutRef = useRef<null | Timer>(null);
  const { closerRoom, roundCounter, lastRoundOutcome, setRoomExpire } =
    useRoomStore();
  const { closeHash } = usePlayerGamesStore();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const onExpire = () => {
    setShowCloseButton(true);
    setRoomExpire(true);
  };
  useEffect(() => {
    if (
      !waitYourMoveShowTimer?.[activeRoomId! + address + chainId + roundCounter]
    ) {
      if (new Date() >= epochToLocalUTC(closerRoom?.deadline)) {
        setShowTimer(true);
      } else {
        setWaitYourMoveShowTimer(
          activeRoomId! + address + chainId + roundCounter,
          new Date(new Date().getTime() + TIME_BEFORE_SHOW_TIMER_IN_MODALS)
        );
        timeOutRef.current = setTimeout(() => {
          setShowTimer(true);
        }, TIME_BEFORE_SHOW_TIMER_IN_MODALS);
      }
    } else {
      const currentTime = new Date();
      const targetTime = new Date(
        waitYourMoveShowTimer?.[
        activeRoomId! + address + chainId + roundCounter
          ] as string
      );
      const diffInMilliseconds = targetTime.getTime() - currentTime.getTime();
      if (diffInMilliseconds >= TIME_BEFORE_SHOW_TIMER_IN_MODALS) {
        setShowTimer(true);
      } else {
        const timeRemaining = targetTime.getTime() - currentTime.getTime();
        timeOutRef.current = setTimeout(() => {
          setShowTimer(true);
        }, timeRemaining);
      }
    }
    return () => clearTimeout(timeOutRef.current as Timer);
  }, []);

  const { closeUnusedRoomHandler, isSuccess, technicalDefeatError } =
    useCloseUnusedRoom();
  const { getTextComponent } = useWaitYourMoveModalText(
    isSuccess,
    showTimer,
    showCloseButton,
    technicalDefeatError,
    lastRoundOutcome
  );
  const colorTextGenerator = () => {
    if (playMoveModalState.error) return 'bg-red';
    if (playMoveModalState.reject) return 'bg-red';
    if (showCloseButton) return 'bg-red';
    if (lastRoundOutcome === RoundOutcome.Lose) return 'bg-red';
    if (lastRoundOutcome === RoundOutcome.Draw) return 'bg-orange';
    return 'bg-green';
  };
  const hostElement = document.querySelector('.player-card-host');
  if (!hostElement) return null;
  return createPortal(
    <>
      <AnimatedTransition show={!!closeHash[chainId!]?.hash}>
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={closeHash[chainId!]?.hash}
          messages={technicalDefeatMessages}
        />
      </AnimatedTransition>
      <AnimatedTransition asFragment show={!closeHash[chainId!]?.hash}>
        <Modal classNames="left-[3%] w-[94%] sm:m-[14px] sm:w-[450px] sm:left-1/2 sm:-translate-x-1/2 lg:left-auto lg:translate-x-0  lg:w-auto lg:mx-[14px]">
          <TextWithLeftColorLine colorClass={colorTextGenerator()}>
            {getTextComponent()}
          </TextWithLeftColorLine>
          <AnimatedTransition show={showCloseButton && !isSuccess}>
            <div className="flex justify-center mt-[20px]">
              <PrimaryButton
                text={'common.technicalDefeat'}
                onClick={closeUnusedRoomHandler}
              />
            </div>
          </AnimatedTransition>
          <AnimatedTransition show={showTimer && !showCloseButton}>
            {!isMobile && <div className="h-[40px]" />}
            <DigitalClock
              key={(epochToLocalUTC(closerRoom?.deadline) as Date).toString()}
              className="absolute  left-1/2 transform -translate-x-1/2 bottom-[-50px]"
              expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
              onExpire={onExpire}
            />
          </AnimatedTransition>
        </Modal>
      </AnimatedTransition>
    </>,
    hostElement as Element
  );
});

export default WaitYourMoveModal;
