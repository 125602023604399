import React from 'react';
import MobileConnectPage from 'modules/ConnectPage/page/MobileConnectPage';
import DesktopConnectPage from 'modules/ConnectPage/page/DesktopConnectPage';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useActiveGameRedirect } from 'modules/Room/Hooks/useActiveGameRedirect';
import { SignModal } from 'modules/ConnectPage/components/SignModal/SignModal';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';

const ConnectPage = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  useActiveGameRedirect();
  const { showSignModal } = useShowSignModal();
  return (
    <>
      <AnimatedTransition show={showSignModal}>
        <SignModal />
      </AnimatedTransition>
      {isMobile ? <MobileConnectPage /> : <DesktopConnectPage />}
    </>
  );
};

export default ConnectPage;
