import React, { InputHTMLAttributes, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Copy from 'assets/copy2.svg?react';
import Send from 'assets/send.svg?react';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useAccount } from 'wagmi';
import { useParams } from 'react-router-dom';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  className?: string;
  balance?: number;
  label?: string;
  copy?: boolean;
}

export const InputHash = ({
  type = 'text',
  className,
  balance,
  label,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { mainSalt, setMainSalt } = usePlayerGamesStore();
  const { address, chainId } = useAccount();
  const { activeRoomId } = useParams();
  const [isBlinking, setIsBlinking] = useState(false);
  const [bgColor, setBgColor] = useState('#030334');
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mainSalt[activeRoomId! + address! + chainId]) {
      setInputValue(mainSalt[activeRoomId! + address! + chainId]!);
    }
  }, [mainSalt, activeRoomId, address, chainId]);

  const copyHandler = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
    navigator.clipboard
      .writeText(inputValue!.toString())
      .then(() => {
        setIsBlinking(true);
        setBgColor('#0000FF');
        setTimeout(() => {
          setIsBlinking(false);
          setBgColor('#030334');
        }, 300);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const saveSalt = () => {
    setMainSalt(inputValue, activeRoomId! + address! + chainId);
  };

  return (
    <div className={`relative w-[100%] opacity-60 ${className ?? ''}`}>

      <input
        ref={inputRef}
        className={`text-center text-[10px] hd:text-[12px] border-[1px] border-indigo text-white rounded-[7px] py-[6px] px-[10px] focus:outline-none focus:border-transparent ${
          type === 'number'
            ? '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
            : ''
        } w-[100%] text-white outline-none`}
        type="text"
        value={inputValue ?? ''}
        readOnly={!!mainSalt[activeRoomId! + address! + chainId]}
        onChange={handleChange}
        style={{
          backgroundColor: bgColor,
          transition: 'background-color 0.3s ease',
        }}
        {...props}
      />

      {mainSalt[activeRoomId! + address! + chainId] ? (
        <Copy
          className={`absolute top-[50%] -translate-y-[50%] right-2 pointer ${
            isBlinking ? 'animate-blink' : ''
          }`}
          onClick={copyHandler}
        />
      ) : (
        <Send
          className={`absolute top-[50%] -translate-y-[50%] right-2 pointer h-4 ${
            isBlinking ? 'animate-blink' : ''
          }`}
          onClick={saveSalt}
        />
      )}
    </div>
  );
};
