import React, { forwardRef } from 'react';
import { useTimer } from 'react-timer-hook';
import './DigitalClock.scss';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { createPortal } from 'react-dom';

interface Props {
  className?: string;
  expiryTimestamp: Date | null;
  onExpire?: () => void;
}

const DigitalClock = forwardRef<HTMLDivElement, Props>(
  ({ className, expiryTimestamp, onExpire }: Props, ref) => {
    const { seconds, minutes } = useTimer({
      expiryTimestamp: new Date(expiryTimestamp as Date),
      onExpire,
    });
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    const isMobile = useMediaQuery('(max-width: 768px)');

    if (!expiryTimestamp) return null;
    if (isMobile) {
      const element = document.querySelector('.mobile-timer');
      if (!element) return null;
      return createPortal(
        <div className="clock py-[5px] px-[18px] mr-2 border border-border rounded-[6px] flex items-center cursor-pointer">
          {formattedMinutes} : {formattedSeconds}
        </div>,
        element
      );
    }
    return (
      <div
        ref={ref}
        className={`p-[2px] rounded-[14px] bg-gradient-to-b from-white to-clock ${className}`}
      >
        <div
          className={`sm:px-[28px] sm:py-[22px] px-[15px] py-[12px] rounded-[14px] bg-gradient-to-b from-violet to-[#172178]`}
        >
          <p className="timer text-[60px] leading-[61px] w-[162px] flex justify-around">
            <span className="w-[30%] flex justify-start">
              {formattedMinutes}
            </span>
            :
            <span className="w-[30%] flex justify-end">{formattedSeconds}</span>
          </p>
        </div>
      </div>
    );
  }
);

export default DigitalClock;
