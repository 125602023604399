import RainbowIcon from 'assets/rainbow-cropped.svg?react';
import CoinbaseIcon from 'assets/Coinbase.svg?react';
import MetamaskIcon from 'assets/metamaskbtn.svg?react';
import TrustWalletIcon from 'assets/trustWallet.svg?react';
import PhantomWallet from 'assets/phantomWallet.svg?react';

export enum Wallet {
  RAINBOW = 'rainbow',
  METAMASK = 'metamask',
  COINBASE = 'coinbase',
  PHANTOM = 'phantom',
  TRUST = 'trust',
}

export const walletDictionary = {
  [Wallet.RAINBOW]: 'Rainbow',
  [Wallet.TRUST]: 'Trust Wallet', 
  [Wallet.METAMASK]: 'Metamask',
  [Wallet.COINBASE]: 'Coinbase',
  [Wallet.PHANTOM]: 'Phantom',
}

export const walletsConfig = [
  {
    wallet: Wallet.RAINBOW,
    icon: RainbowIcon,
    name: walletDictionary[Wallet.RAINBOW],
  },
  {
    wallet: Wallet.METAMASK,
    icon: MetamaskIcon,
    name: walletDictionary[Wallet.METAMASK],
  },
  {
    wallet: Wallet.COINBASE,
    icon: CoinbaseIcon,
    name: walletDictionary[Wallet.COINBASE],
  },
  {
    wallet: Wallet.PHANTOM,
    icon: PhantomWallet,
    name: walletDictionary[Wallet.PHANTOM],
  },
  {
    wallet: Wallet.TRUST,
    icon: TrustWalletIcon,
    name: walletDictionary[Wallet.TRUST],
  },
]
