import React from 'react';
import './MainPageNetworkBtn.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  svg: React.ReactNode;
  active: boolean;
  className?: string;
  labelYellow?: string;
  labelWhite?: string;
}

const MainPageNetworkBtn = ({
  labelYellow,
  labelWhite,
  active,
  svg,
  className,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <button
        disabled
        className={`${
          active ? 'MainPageNetworkBtn--active border-yellow border-4' : 'border-white'
        } ${
          className ? className : ''
        } mb-[8px] grid place-items-center border rounded-[5px] bg-gradient-to-b from-violet to-[#172178] w-[54px] sm:w-[80px] h-[54px]  sm:h-[80px]`}
      >
        {svg}
      </button>
      <p className="text-yellow font-bold text-[12px] sm:text-[20px] text-center sm:whitespace-nowrap">
        {t(labelYellow!)} <span className="text-white">{t(labelWhite!)}</span>
      </p>
    </div>
  );
};

export default MainPageNetworkBtn;
