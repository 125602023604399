import React from 'react';

interface Props {
  children: React.ReactNode;
  colorClass: string;
  className?: string;
}

const TextWithLeftColorLine = ({ children, colorClass, className }: Props) => {
  return (
    <div className={`w-full flex ${className}`}>
      <div className={`min-w-[7px] rounded-[4px] mr-[25px] ${colorClass}`} />
      <div>{children}</div>
    </div>
  );
};

export default TextWithLeftColorLine;


