import { constants } from 'ethers';

export const NULL_MOVE =
  '0x0000000000000000000000000000000000000000000000000000000000000000';

export const NULL_ADDRESS = constants.AddressZero;

export const TOTAL_WINS_TO = 3n;

export const RANGE_OF_GAME_REQUEST = 50;

