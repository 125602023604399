import rock from 'assets/png/stone.png';
import paper from 'assets/png/paper.png';
import scissors from 'assets/png/scissors.png';
import { MovesEnum } from 'shared/constants/shared-enums';
import React from 'react';

interface IconProps {
  src: string;
  alt: string;
  label: string;
  winner: boolean;
}

const Icon: React.FC<IconProps> = ({ src, alt, label, winner }) => (
  <div
    className={`flex flex-col items-center my-[10px] rounded-[7px] w-[44px] sm:w-[54px] h-[34px] sm:h-[58px]  ${
      winner ? 'bg-blue-text border border-blue-border' : ''
    }`}
  >
    <img className="w-[23px] sm:w-[40px]" src={src} alt={alt} />
    <p
      className={`text-[9px] sm:text-[12px] text-center ${
        winner ? 'text-white' : 'text-simple-text'
      }`}
    >
      {label}
    </p>
  </div>
);

export const renderIcon = (move?: MovesEnum, winner?: boolean) => {
  switch (move) {
    case MovesEnum.Paper:
      return <Icon src={paper} alt="paper" label="Paper" winner={!!winner} />;
    case MovesEnum.Rock:
      return <Icon src={rock} alt="rock" label="Rock" winner={!!winner} />;
    case MovesEnum.Scissors:
      return (
        <Icon
          src={scissors}
          alt="scissors"
          label="Scissors"
          winner={!!winner}
        />
      );
    default:
      return null;
  }
};
