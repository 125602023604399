import React from 'react';
import ModalsConnectCreate from './components/ModalsConnectCreate/ModalsConnectCreate';
import ModalsPlayGame from './components/ModalsPlayGame/ModalsPlayGame';

const RoomModals = () => {
  return (
    <>
      <ModalsConnectCreate />
      <ModalsPlayGame />
    </>
  );
};

export default RoomModals;
