import { useReadRspContractRounds } from 'shared/features/contract.abi';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useRoomStore } from 'shared/store/roomStore';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import * as Sentry from '@sentry/react';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { utils } from 'ethers';
import { generateSubSalt } from 'shared/constants/utils';
import { useAccount } from 'wagmi';

const findMove = async (hash: `0x${string}`, salt: string, address: string) => {
  for (const move of [1, 2, 3]) {
    const computedHash = utils.solidityKeccak256(
      ['string', 'uint8', 'address'],
      [salt, move, address]
    ) as `0x${string}`;
    if (computedHash === hash) {
      return move;
    }
  }
  return null;
};

export const useRoundInfo = () => {
  const { activeRoomId } = useParams();
  const { roundCounter, closerRoom, setRoundStage, setRoundMoves, roomStatus } =
    useRoomStore();

  const { data, refetch } = useReadRspContractRounds({
    args: [BigInt(activeRoomId ?? 0), BigInt(roundCounter)],
    query: { enabled: !!activeRoomId },
  });

  const { amIPlayerA, someoneElseGame } = useRoomStore();
  const { mainSalt } = usePlayerGamesStore();
  const { setPlayMove, playMoves } = usePlayerGamesStore();
  const { address, chainId } = useAccount();

  const processRoundData = async () => {
    if (data && activeRoomId && address) {
      const [moveA, moveB, encrMoveA, encrMoveB, winner, stage, roundStage] =
        data;
      setRoundStage(roundStage);
      Sentry.setTag('roundStage', roundStage);
      setRoundMoves({
        playerA: { encrMove: encrMoveA, move: moveA },
        playerB: { encrMove: encrMoveB, move: moveB },
      });

      const currentPlayMoveKey = `${activeRoomId}${address}${chainId}${roundCounter}`;
      const isPlayerMoveMissing =
        (amIPlayerA &&
          !moveA &&
          encrMoveA !==
            '0x0000000000000000000000000000000000000000000000000000000000000000' &&
          !playMoves[currentPlayMoveKey]?.move) ||
        (!amIPlayerA &&
          !moveB &&
          encrMoveB !==
            '0x0000000000000000000000000000000000000000000000000000000000000000' &&
          !playMoves[currentPlayMoveKey]?.move);
      if (isPlayerMoveMissing && !someoneElseGame) {
        const salt = await generateSubSalt(
          mainSalt[`${activeRoomId}${address}${chainId}`] as string,
          roundCounter
        );
        const moveHash = amIPlayerA ? encrMoveA : encrMoveB;
        const move = await findMove(moveHash, salt, address);
        if (!move) return;
        setPlayMove(currentPlayMoveKey, {
          playerAddress: address,
          salt,
          move,
          moveHash: null,
          revealHash: null,
        });
      }
    }
  };

  useEffect(() => {
    processRoundData();
  }, [data, roundCounter]);

  useEffect(() => {
    if (roomStatus !== RoomStatusEnum.Closed) {
      refetch();
    }
  }, [closerRoom, roomStatus, refetch]);
};
