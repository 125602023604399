import React from 'react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  text: string;
  onClick: () => void;
  className?: string;
}

const SecondaryBtn = ({text, onClick, className, ...rest}: Props) => {
  return (
    <button
      className={`${className} border-[1px] border-white rounded-[7px] pt-[10px] pb-[6px] sm:pt-[14px] sm:pb-[10px] px-[10px] sm:px-[36px] focus:outline-none`}
      onClick={onClick}
      {...rest}
    >
      <span className='text-white text-[18px] sm:text-[21px] font-medium leading-5 whitespace-nowrap'>{text}</span>
    </button>
  );
};

export default SecondaryBtn;

