import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { FC } from 'react';
import { config } from 'shared/config';
import { RainbowKitProvider, Theme } from '@rainbow-me/rainbowkit';
import { myCustomTheme } from 'shared/styles/rainbowKitTheme';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient();

interface Props {
  children: JSX.Element;
}

export const Web3WagmiConfig: FC<Props> = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          locale="en-US"
          theme={myCustomTheme}
          modalSize="wide"
        >
          {children}
        </RainbowKitProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </WagmiProvider>
  );
};
