import React, { useState, useEffect, useRef } from 'react';
import Dropdown from 'shared/components/DropDown/DropDown';
import ArrowIcon from 'assets/arrow.svg?react';

export interface selectOption {
  value: string | number;
  label: string;
  tooltip?: string;
}

interface Props {
  options: selectOption[];
  selectedOption: selectOption;
  onSelect: (option: selectOption) => void;
  className?: string;
  disabled?: boolean;
}

const Select = ({
  options,
  selectedOption,
  onSelect,
  className,
  disabled,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleOpenMenu = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: selectOption) => {
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={selectRef} className={`relative ${className}`}>
      <div
        className={`flex items-center justify-center border-[1px] border-indigo text-white rounded-[7px] py-[8px] sm:py-[12px] px-[15px] sm:px-[22px] focus:outline-none focus:border-transparent ${
          disabled ? 'pointer-events-none bg-gray' : 'cursor-pointer bg-border'
        }`}
        onClick={handleOpenMenu}
      >
        <span className="mr-[12px]">
          {selectedOption ? selectedOption.label : 'Select an option'}
        </span>
        <ArrowIcon
          className={`w-[12px] h-[12px] color-white transform transition-transform ${
            isOpen ? 'rotate-180' : ''
          } `}
        />
      </div>
      <Dropdown
        open={isOpen}
        list={options}
        position={{ top: 58, right: 0 }}
        onClick={handleOptionClick}
      />
    </div>
  );
};

export default Select;
