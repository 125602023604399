import React from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useRoomStore } from 'shared/store/roomStore';
import { useParams } from 'react-router-dom';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import './Bank.scss';

const Bank = () => {
  const { t } = useTranslation();
  const { playerB, bet } = useRoomStore();
  const { selectedCurrency } = useCurrenciesStore();
  const twoPlayersInRoom =  playerB.address !== NULL_ADDRESS;
  const { activeRoomId } = useParams();
  return (
    <div className="flex flex-col items-center">
      <h4 className="text-blue-text font-xl font-medium mb-2">Bank</h4>
      <div
        className={`flex justify-around border-border rounded-[11px] w-full overflow-hidden
      ${
        activeRoomId
          ? twoPlayersInRoom
            ? 'bank-100-orange'
            : 'bank-50-orange'
          : 'bg-black bg-opacity-30'
      }
      `}
      >
        <AnimatedTransition show={!!activeRoomId} asFragment>
          <span className="font-medium text-[44px] z-20 text-white outlined-text break-words">{`${
            twoPlayersInRoom ? bet * 2 : bet
          } ${selectedCurrency?.symbol ?? ''}`}</span>
        </AnimatedTransition>
        <AnimatedTransition show={!activeRoomId} asFragment>
          <span className="text-[44px] text-gray  uppercase overflow-wrap-break">
            {t('common.empty')}
          </span>
        </AnimatedTransition>
      </div>
    </div>
  );
};

export default Bank;
