export enum LoadersStateEnum {
  None,
  ChooseMove,
  MoveDone,
  Reveal,
  RevealDone,
  Sleep,
}

export enum FinishStateEnum {
  NoOneWin = 'NoOneWin',
  OpponentSurrender = 'OpponentSurrender',
  YouExitRoom = 'YouExitRoom',
  YouLost = 'YouLost',
  YouTechnicallyLost = 'YouTechnicallyLost',
  YouTechnicallyWin = 'YouTechnicallyWin',
  YouWin = 'YouWin'
}
