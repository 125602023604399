import React, { useEffect } from 'react';
import { useSimulateContract, useWriteContract } from 'wagmi';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { WriteError } from 'shared/types/app-types';
import * as Sentry from '@sentry/react';

const spenderAddress = import.meta.env.VITE_RSP_CONTRACT_ADDRESS;

interface Props {
  debouncedBetInTokenAmount: number;
  finishApproveHandler: (approveState: {
    reject: boolean;
    error: boolean;
  }) => void;
}

const Approve = ({
  debouncedBetInTokenAmount,
  finishApproveHandler,
}: Props) => {
  const { selectedCurrency } = useCurrenciesStore();
  const { setAllowanceHash, setAllowanceAmount, allowanceTransactions } =
    usePlayerGamesStore();
  const { isSuccess: simulateSuccess, isError: simulateError } =
    useSimulateContract({
      address: selectedCurrency?.address ?? NULL_ADDRESS,
      abi: [
        {
          constant: false,
          inputs: [
            { name: 'spender', type: 'address' },
            { name: 'amount', type: 'uint256' },
          ],
          name: 'approve',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ] as const,
      functionName: 'approve',
      args: [spenderAddress, BigInt(debouncedBetInTokenAmount)],
    });
  const {
    writeContract: writeApproveAmount,
    isSuccess,
    data,
    isError,
    error,
    isPending,
  } = useWriteContract();
  const approveHandler = (amount: bigint) => {
    if (isPending) return;
    setAllowanceAmount(Number(amount));
    writeApproveAmount?.({
      address: selectedCurrency?.address ?? NULL_ADDRESS,
      abi: [
        {
          constant: false,
          inputs: [
            { name: 'spender', type: 'address' },
            { name: 'amount', type: 'uint256' },
          ],
          name: 'approve',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ] as const,
      functionName: 'approve',
      args: [spenderAddress, amount],
    });
  };
  const {
    isSuccess: isSuccessApproveAmount,
    isError: isErrorApproveAmount,
    error: transactionError,
    limitExceed,
  } = useTransactionReceipt(data);
  useEffect(() => {
    if (simulateSuccess) {
      approveHandler(BigInt(debouncedBetInTokenAmount));
    }
  }, [simulateSuccess]);
  useEffect(() => {
    if (simulateError) {
      approveHandler(0n);
    }
  }, [simulateError]);
  useEffect(() => {
    if (isSuccess) {
      setAllowanceHash(data);
    }
  }, [isSuccess, data]);
  useEffect(() => {
    if ((isError && error) || limitExceed) {
      Sentry.captureException(error);
      const typedError = error as WriteError | undefined;
      if (typedError?.cause?.code) {
        finishApproveHandler({
          reject: typedError.cause.code === 4001,
          error: typedError.cause.code !== 4001,
        });
      } else {
        finishApproveHandler({
          reject: false,
          error: true,
        });
      }
      setAllowanceHash(null);
    }
  }, [isError, error, limitExceed]);
  useEffect(() => {
    if (isErrorApproveAmount) {
      Sentry.captureException(transactionError);
      finishApproveHandler({
        reject: false,
        error: true,
      });
    }
  }, [isErrorApproveAmount, transactionError]);
  useEffect(() => {
    if (isSuccessApproveAmount) {
      if (allowanceTransactions.amount === 0) {
        approveHandler(BigInt(debouncedBetInTokenAmount));
      } else {
        finishApproveHandler({ reject: false, error: false });
      }
      setAllowanceHash(null);
    }
  }, [isSuccessApproveAmount]);
  return null;
};
export default Approve;
