import Modal from 'shared/components/Modal/Modal';
import { Wallet, walletDictionary } from '../../page/constants';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { useTranslation } from 'react-i18next';
import Copy from 'assets/copy.svg?react';
import Check from 'assets/check.svg?react';
import PrimaryButtonWithSvg from 'shared/components/PrimaryButton/PrimaryButtonWithSvg';
import { useState } from 'react';

interface IMobileWalletRedirectModalProps {
  wallet: Wallet;
  onClose: () => void;
}

export const MobileWalletRedirectModal = ({
                                            wallet,
                                            onClose,
                                          }: IMobileWalletRedirectModalProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [cantCopy, setCantCopy] = useState(false);
  const { t } = useTranslation();
  const appUrl = import.meta.env.VITE_RPS_APP_URL;

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(`${appUrl}?isAutoConnectAvailable=true`);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 4000);
    } catch (error) {
      setCantCopy(true);
    }
  };

  return (
    <Modal classNames="w-[95%]">
      <div className="w-full">
        <div className="text-white">
          <p>{t('modals.mobileWalletRedirect.start')}</p>
          <p>{t('modals.mobileWalletRedirect.copy')}</p>
          <p>
            {t('modals.mobileWalletRedirect.open', {
              wallet: walletDictionary[wallet],
            })}
          </p>
          <p>{t('modals.mobileWalletRedirect.follow')}</p>
        </div>
        {!cantCopy && (
          <div className="flex align-middle gap-3 mt-3">
            <PrimaryButtonWithSvg
              svg={isCopied ? <Check /> : <Copy />}
              text={t('common.copyLink')}
              clickHandler={handleCopy}
            />

            <PrimaryButton text={t('common.close')} onClick={onClose} />
          </div>
        )}
      </div>
    </Modal>
  );
};
