import React, { forwardRef } from 'react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  svg: React.ReactNode;
  text?: string;
  clickHandler: () => void;
  disabled?: boolean;
  className?: string;
}

const PrimaryButtonWithSvg = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { className, svg, text, clickHandler, disabled, ...rest } = props;
    return (
      <button
        ref={ref}
        className={`rounded-[8px] bg-primary-btn active:bg-primary-active hover:bg-primary-btn-hover ${
          className ? className : ''
        }`}
        onClick={clickHandler}
        disabled={disabled}
        {...rest}
      >
        <div className="rounded-[8px] text-white m-[1px] py-[10px] h-full flex items-center px-[14px] bg-primary-btn-inner font-medium text-[10px] [&>svg]:w-[16px] [&>svg]:h-[16px] font-semibold text-[14px] sm:text-[22px] gap-2">
          {text}
          {svg}
        </div>
      </button>
    );
  }
);

export default PrimaryButtonWithSvg;
