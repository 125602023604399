import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGlobalStore } from 'shared/store/globalStore';

export const useGetURLRef = () => {
  const { setInvited } = useGlobalStore();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref');
    if (ref) {
      setInvited(ref as `0x${string}`);
      navigate(location.pathname, { replace: true });
    }
  }, []);
};
