import { WalletButton } from '@rainbow-me/rainbowkit';
import { Wallet } from 'modules/ConnectPage/page/constants';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface CustomWalletButtonProps {
  wallet: Wallet;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  isCustomConnectPossible: boolean;
  onCustomConnect: () => void;
}

const checkIsConnectPossible = (wallet: string) => {
  if (navigator.userAgent.includes('MetaMask') && wallet === Wallet.METAMASK
      || window.ethereum && window.ethereum.isCoinbaseWallet && wallet === Wallet.COINBASE
      || window.ethereum && window.ethereum.isRainbow && wallet === Wallet.RAINBOW
      // @ts-ignore:next-line
      || window.solana && window.solana.isPhantom && wallet === Wallet.PHANTOM
      || window.ethereum && window.ethereum.isTrust && wallet === Wallet.TRUST) {
      return true;
  }

  return false;
}

const CustomWalletButton: React.FC<CustomWalletButtonProps> = ({
  wallet,
  Icon,
  name,
  isCustomConnectPossible,
  onCustomConnect,
}) => {
  const [searchParams] = useSearchParams();

  return (
    <WalletButton.Custom wallet={wallet}>
      {({ ready, connect }) => {
        useEffect(() => {
          if (searchParams.get('isAutoConnectAvailable') && checkIsConnectPossible(wallet)) {
            connect();
          }
        }, [])

        const handleConnect = () => {  
          if (isCustomConnectPossible) {
            onCustomConnect()
            
            return;
          }

          connect();
        }

        return (
          <button type="button" disabled={!ready} onClick={handleConnect}>
            <div className="flex items-center flex-col">
              <div className="[&>svg]:w-[50px] [&>svg]:h-[50px]">
                <Icon />
              </div>
              <p className="text-white text-[10px] pt-[6px]">{name}</p>
            </div>
          </button>
        );
      }}
    </WalletButton.Custom>
  );
};

export default CustomWalletButton;
