import { CHAINS_TOKENS_CONFIG } from 'shared/constants';
import {
  compareLowerCase,
} from 'shared/constants/utils';
import { Chain } from 'viem';

export type CurrencyType = Record<string, any> & {
  address: `0x${string}`;
  chainId: string | number;
  decimals: number;
  symbol: string;
  bet: string[];
  minBet: number;
};

export const getTokenData = (currencies: CurrencyType[], tokenSymbol: string) =>
  currencies.find(({ symbol }) => compareLowerCase(symbol, tokenSymbol)) ||
  null;

const getConfig = ({
  config,
  currency,
  defaultValue,
}: {
  config: Record<string, any>;
  currency: CurrencyType;
  defaultValue: any;
}) => config?.[currency.symbol] ?? defaultValue;

export const getAvailableCurrencies = (chain: Chain) => {
  const network = chain.name;
  const currencies = CHAINS_TOKENS_CONFIG[
    network as keyof typeof CHAINS_TOKENS_CONFIG
  ]
    ? [...CHAINS_TOKENS_CONFIG[network as keyof typeof CHAINS_TOKENS_CONFIG]]
    : [];
  return currencies;
  // return getSortAlphabetically(currencies, 'symbol');
};

export const formatNumber = (num: number) => {
  if (num % 1000000 === 0) {
    return num / 1000000 + 'M';
  } else if (num % 1000 === 0) {
    return num / 1000 + 'K';
  } else {
    return num;
  }
};
