export const networkHandler = (chainId: number) => {
  if (!chainId) return '';
  switch (chainId) {
    case 137:
      return 'https://polygonscan.com/tx';
    case 42161:
      return 'https://arbiscan.io/tx';
    case 1:
      return 'https://etherscan.io/tx';
    case 8453:
      return 'https://basescan.org/tx';
    default:
      return '';
  }
};