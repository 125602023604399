import { CHAINS } from 'shared/config';
import {
  ARBITRUM_TOKENS,
  BASE_TOKENS,
  ETHEREUM_TOKENS,
  POLYGON_TOKENS,
} from 'shared/contract';

export const CHAINS_TOKENS_CONFIG = {
  [CHAINS.mainnet.name]: ETHEREUM_TOKENS,
  [CHAINS.polygon.name]: POLYGON_TOKENS,
  [CHAINS.arbitrum.name]: ARBITRUM_TOKENS,
  [CHAINS.base.name]: BASE_TOKENS,
};
