export const allowanceMessages = [
  'textLoaders.allowance.waiting',
  'textLoaders.allowance.processing',
  'textLoaders.allowance.callingVitalik',
];

export const waitYourRevealMessages = [
  'textLoaders.waitYourReveal.god',
  'textLoaders.waitYourReveal.disclose',
  'textLoaders.waitYourReveal.witness',
  'textLoaders.waitYourReveal.awaiting',
  'textLoaders.waitYourReveal.dice',
];

export const creatingMessages = [
  'textLoaders.creating.initiate',
  'textLoaders.creating.permission',
  'textLoaders.creating.cables',
  'textLoaders.creating.crafting',
  'textLoaders.creating.terrain',
];

export const takeMoneyBackMessages = [
  'textLoaders.takeMoneyBack.hamster',
  'textLoaders.takeMoneyBack.stash',
  'textLoaders.takeMoneyBack.elon',
  'textLoaders.takeMoneyBack.gods',
  'textLoaders.takeMoneyBack.pigeons',
];

export const technicalDefeatMessages = [
  'textLoaders.technicalDefeat.sweetVictory',
  'textLoaders.technicalDefeat.asleep',
  'textLoaders.technicalDefeat.countdown',
  'textLoaders.technicalDefeat.judge',
];

export const waitOpponentMoveMessages = [
  'textLoaders.waitOpponentMove.road',
  'textLoaders.waitOpponentMove.ai',
  'textLoaders.waitOpponentMove.move',
  'textLoaders.waitOpponentMove.coffee',
  'textLoaders.waitOpponentMove.loading',
];

export const waitOpponentRevealMessages = [
  'textLoaders.waitOpponentReveal.breath',
  'textLoaders.waitOpponentReveal.art',
  'textLoaders.waitOpponentReveal.calculating',
  'textLoaders.waitOpponentReveal.coffeeBreak',
  'textLoaders.waitOpponentReveal.verifying',
];

export const waitYourMoveMessages = [
  'textLoaders.waitYourMove.transaction',
  'textLoaders.waitYourMove.blockchain',
  'textLoaders.waitYourMove.meeting',
  'textLoaders.waitYourMove.check',
  'textLoaders.waitYourMove.queue',
];

export const madeMoveMessages = ['textLoaders.waitingMan.madeMove'];

export const madeRevealMessages = ['textLoaders.waitingMan.madeReveal'];

export const connectingGameMessages = [
  'textLoaders.connectingGame.universe',
  'textLoaders.connectingGame.verification',
  'textLoaders.connectingGame.imminent',
  'textLoaders.connectingGame.syncing',
  'textLoaders.connectingGame.aligning',
];


export const lookingForOpponent = [
  'textLoaders.lookingForOpponent.looking',
  'textLoaders.lookingForOpponent.searching',
  'textLoaders.lookingForOpponent.scouting',
  'textLoaders.lookingForOpponent.match',
  'textLoaders.lookingForOpponent.finding',
];
