import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useMoveHandler } from 'modules/Room/Hooks/useMoveHandler';
import { RockPaperScissors } from 'modules/Room/features/RockPaperScissors/RockPaperScissors';
import { useRoomStore } from 'shared/store/roomStore';

const MobilePlayMenu = () => {
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const { playMoves, setPlayMove } = usePlayerGamesStore();
  const { roundCounter } = useRoomStore();

  const selectMoveHandler = useMoveHandler();
  return (
    <div className="w-full pb-[12px] sm:w-[80%] sm:ml-[20%] px-[14px] flex items-end justify-end">
      <RockPaperScissors
        move={
          playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move
        }
        selectMove={selectMoveHandler}
      />
    </div>
  );
};
export default MobilePlayMenu;
