import { CurrencyType } from 'shared/web3';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface State {
  availableCurrencies: CurrencyType[];
  setAvailableCurrencies: (currencies: CurrencyType[]) => void;
  currencyForSelect: { label: string; value: string }[];
  setCurrenciesForSelect: (
    currencies: { label: string; value: string }[]
  ) => void;
  selectedCurrency: CurrencyType | null;
  setSelectedCurrency: (symbol: string) => void;
  userBets: {
    [key: number]: {
      [symbol: string]: number;
    };
  };
  setUserBet: (key: number, bet: number, symbol: string) => void;
}

const initialState = {
  availableCurrencies: [],
  currencyForSelect: [],
  selectedCurrency: null,
  userBets: {},
};

export const useCurrenciesStore = create<State>()(
  persist(
    immer(
      devtools((set) => ({
        ...initialState,
        setAvailableCurrencies: (newValue) =>
          set(() => ({
            availableCurrencies: newValue,
          })),
        setCurrenciesForSelect: (newValue) =>
          set(() => ({
            currencyForSelect: newValue,
          })),
        setSelectedCurrency: (symbol) =>
          set((state) => ({
            selectedCurrency: state.availableCurrencies.find(
              (el) => el.symbol === symbol
            ),
          })),
        setUserBet: (key, bet, symbol) =>
          set((state) => ({
            userBets: {
              ...state.userBets,
              [key]: {
                [symbol]: bet,
              },
            },
          })),
      }))
    ),
    { name: 'currenciesStore' }
  )
);
