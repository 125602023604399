import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { LANGUAGE_FALLBACK, LANGUAGES_LIST } from 'shared/i18n/constants';

// import translationEN from '../../../public/locales/en/translation.json';
// import translationHI from '../../../public/locales/hi/translation.json';
//
// const resources = {
//   en: {
//     translations: translationEN
//   },
//   hi: {
//     translations: translationHI
//   }
// }

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: LANGUAGE_FALLBACK,
    keySeparator: '.',
    supportedLngs: LANGUAGES_LIST,
  });

export default i18n;