import { useSwitchChain } from 'wagmi';
import { selectOption } from 'shared/components/Select/Select';
import { useAccount } from 'wagmi';
import ReactGA from 'react-ga4';
import { useRoomStore } from 'shared/store/roomStore';

export const useSwitchChainHandler = () => {
  const { switchChainAsync, isSuccess, isPending, isError } = useSwitchChain();
  const { address, chainId } = useAccount();

  return async (option: selectOption) => {
    await switchChainAsync({ chainId: option.value as number });
    useRoomStore.getState().resetRoom(address?.toLowerCase() as `0x${string}`);
    const startWithZeroX = address?.startsWith('0x');
    const walletWithoutPrefix = startWithZeroX ? address?.substring(2) :address;
    const eventOptions = {
      category: 'game',
      action: 'Switch chain',
      wallet: walletWithoutPrefix,
      startWithZeroX: startWithZeroX,
      chainId: chainId
    };
    ReactGA.event('switch', eventOptions);
  };
};
