import Bowser from 'bowser';

export const isRedirectAvailable = (navigator: Navigator) => {
  if (window.ethereum && window.ethereum.isCoinbaseWallet) {
    return false;
  }

  if (navigator.userAgent.includes('MetaMask')) {
    return false;
  }

  // @ts-ignore:next-line
  if (window.solana && window.solana.isPhantom === true) {
    return false;
  }

  if (window.ethereum && window.ethereum.isTrust === true) {
    return false;
  }

  if (window.ethereum && window.ethereum.isRainbow) {
    return false;
  }

  if (Bowser.parse(window.navigator.userAgent).platform.type === 'desktop') {
    return false;
  }

  return true;
};
