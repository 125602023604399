import { FC, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoomStore } from 'shared/store/roomStore';
import { Avatar } from 'shared/components/Avatar/Avatar';
import { RockPaperScissors } from 'modules/Room/features/RockPaperScissors/RockPaperScissors';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import playerCardBg from 'assets/png/player-card.png';
import './PlayerCard.css';
import { useMoveHandler } from 'modules/Room/Hooks/useMoveHandler';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useParams } from 'react-router-dom';
import {
  MovesEnum,
  RoomStatusEnum,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import RockScissorsPaperLoader from 'shared/components/Loaders/RockScissorsPaperLoader/RockScissorsPaperLoader';
import ZIcon from 'assets/animation/sleep/Z.svg?react';
import WaitingMan from 'shared/components/Loaders/WaitingMan/WaitingMan';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { ModalTypeEnum } from 'modules/Room/RoomModals/constants/modalEnums';
import {
  madeMoveMessages,
  madeRevealMessages,
} from 'shared/constants/text-loaders';
import GameFinishViews from 'modules/Room/features/GameFinishViews/GameFinishViews';
import victory from 'assets/png/victory.png';
import defeat from 'assets/png/defeat.png';
import { LoadersStateEnum } from 'modules/Room/constants/enums';
import { NULL_MOVE } from 'modules/Room/constants/constants';

interface Props {
  playerAddress: `0x${string}`;
}

export const PlayerCard: FC<Props> = ({ playerAddress }) => {
  const { activeRoomId } = useParams();
  const [iAmWinner, setIAmWinner] = useState<boolean>(false);
  const [iAmLooser, setIAmLooser] = useState<boolean>(false);
  const [loadersState, setLoadersState] = useState<LoadersStateEnum>(
    LoadersStateEnum.None
  );

  const { address, chainId } = useAccount();
  const amIHost = playerAddress === address?.toLowerCase();

  const [isShowEndGameAnimation, setIsShowEndGameAnimation] =
    useState<boolean>(false);
  const {
    amIPlayerA,
    someoneElseGame,
    roundCounter,
    playerA,
    playerB,
    roomStatus,
    winnerStatus,
    roundMoves,
  } = useRoomStore();

  const { modalState, closeAllModals } = useModalStore();
  let sleepTimeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const clearCurrentTimeout = () => {
      if (sleepTimeoutRef.current) {
        clearTimeout(sleepTimeoutRef.current);
      }
    };

    const setPlayerLoaderState = (opponentMove: `0x${string}`) => {
      if (opponentMove === NULL_MOVE) {
        setLoadersState(LoadersStateEnum.ChooseMove);
      } else {
        setLoadersState(LoadersStateEnum.MoveDone);
      }
    };

    const setRevealLoaderState = (opponentEncrMove: MovesEnum) => {
      if (opponentEncrMove === MovesEnum.None) {
        setLoadersState(LoadersStateEnum.Reveal);
      } else {
        setLoadersState(LoadersStateEnum.RevealDone);
      }
    };

    clearCurrentTimeout();

    if (
      modalState[ModalTypeEnum.WAIT_YOUR_MOVE] ||
      modalState[ModalTypeEnum.WAIT_YOUR_APPROVE]
    ) {
      if (amIPlayerA) {
        setPlayerLoaderState(roundMoves.playerB.encrMove);
      } else {
        setPlayerLoaderState(roundMoves.playerA.encrMove);
      }
    } else if (
      modalState[ModalTypeEnum.WAIT_OPPONENT_MOVE] ||
      modalState[ModalTypeEnum.WAIT_OPPONENT_REVEAL]
    ) {
      setLoadersState(LoadersStateEnum.None);
      sleepTimeoutRef.current = setTimeout(() => {
        setLoadersState(LoadersStateEnum.Sleep);
      }, 40000);
    } else if (modalState[ModalTypeEnum.WAIT_YOUR_REVEAL]) {
      if (amIPlayerA) {
        setRevealLoaderState(roundMoves.playerB.move);
      } else {
        setRevealLoaderState(roundMoves.playerA.move);
      }
    } else {
      setLoadersState(LoadersStateEnum.None);
    }

    return clearCurrentTimeout;
  }, [modalState, amIPlayerA, roundMoves]);

  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed) {
      if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerA
      ) {
        setIAmWinner(playerAddress === playerA.address);
        setIAmLooser(playerAddress === playerB.address);
        if (amIHost) {
          closeAllModals();
          setIsShowEndGameAnimation(true);
        }
      } else if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerB
      ) {
        setIAmWinner(playerAddress === playerB.address);
        setIAmLooser(playerAddress === playerA.address);
        if (amIHost) {
          closeAllModals();
          setIsShowEndGameAnimation(true);
        }
      }
    }
  }, [winnerStatus, roomStatus, playerA, playerB, playerAddress]);

  useEffect(() => {
    if (!activeRoomId || !winnerStatus) {
      setIAmWinner(false);
      setIAmLooser(false);
    }
  }, [activeRoomId, winnerStatus]);

  const { t } = useTranslation();

  const { playMoves } = usePlayerGamesStore();

  const selectMoveHandler = useMoveHandler();
  const playerName = () => {
    if (someoneElseGame) {
      if (playerB.address === playerAddress) {
        return 'common.playerB';
      } else {
        return 'common.playerA';
      }
    } else if (amIHost) {
      return 'common.you';
    } else if (!activeRoomId && playerAddress === NULL_ADDRESS) {
      return 'common.noOpponent';
    } else if (playerAddress === NULL_ADDRESS) {
      return 'common.connecting';
    } else {
      return 'common.opponent';
    }
  };

  const showAnimation = () => {
    if (!amIHost && loadersState === LoadersStateEnum.ChooseMove) return true;
    if (!amIHost && loadersState === LoadersStateEnum.Reveal) return true;
    if (
      !amIHost &&
      (loadersState === LoadersStateEnum.MoveDone ||
        loadersState === LoadersStateEnum.RevealDone)
    )
      return true;
    return false;
  };

  const renderAnimation = () => {
    if (!amIHost && loadersState === LoadersStateEnum.ChooseMove)
      return (
        <RockScissorsPaperLoader
          messages={['common.pickingHand']}
          className={
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          }
        />
      );
    if (!amIHost && loadersState === LoadersStateEnum.Reveal)
      return (
        <MintingLoader
          messages={['common.revealing']}
          className={
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          }
        />
      );
    if (
      !amIHost &&
      (loadersState === LoadersStateEnum.MoveDone ||
        loadersState === LoadersStateEnum.RevealDone)
    )
      return (
        <WaitingMan
          messages={
            loadersState === LoadersStateEnum.MoveDone
              ? madeMoveMessages
              : madeRevealMessages
          }
          className={
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          }
        />
      );
  };
  const renderGameResultImage = () => {
    if (!amIHost || !isShowEndGameAnimation) {
      return null;
    }

    setTimeout(() => {
      setIsShowEndGameAnimation(false);
    }, 6000);

    if (!amIPlayerA) {
      if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerB
      ) {
        return (
          <img
            src={victory}
            alt="victory image"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
          />
        );
      }

      if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerA
      ) {
        return (
          <img
            src={defeat}
            alt="defeat image"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
          />
        );
      }
    }

    if (
      winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
      winnerStatus === WinnerStatusEnum.TechnicalWinnerA
    ) {
      return (
        <img
          src={victory}
          alt="victory image"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
        />
      );
    }

    if (
      winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
      winnerStatus === WinnerStatusEnum.TechnicalWinnerB
    ) {
      return (
        <img
          src={defeat}
          alt="defeat image"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
        />
      );
    }

    return null;
  };

  return (
    <div
      className={`
        flex flex-col w-full h-full items-center py-4 rounded-[22px] 
        border border-border transition bg-opacity-30 bg-background 
        justify-between relative watch-game-card 
        ${amIHost ? 'player-card-host' : 'player-card-opponent'}
        ${iAmWinner ? 'player-card-victory' : ''}
        ${iAmLooser ? 'player-card-lose' : ''}
      `}
    >
      <div className="flex flex-col items-center">
        <div className="flex flex-row mb-[10px]">
          {playerAddress !== NULL_ADDRESS ? (
            <Avatar
              userAddress={playerAddress}
              className={`w-[97px] h-[97px]`}
            />
          ) : (
            <div className="w-[97px] h-[97px] flex justify-center items-center rounded-[12px] bg-deep-blue">
              <span className="text-blue text-[58px]">?</span>
            </div>
          )}
        </div>
        <AnimatedTransition
          show={!amIHost && loadersState === LoadersStateEnum.Sleep}
        >
          <div className="absolute top-[16px] left-1/2 transform -translate-x-1/2 z-10">
            <ZIcon className="zIcon absolute top-5 left-10" />
            <ZIcon className="zIcon absolute top-5 left-10" />
            <ZIcon className="zIcon absolute top-5 left-10" />
          </div>
        </AnimatedTransition>

        <div className="background-dark-background border border-border rounded-lg px-[32px] py-[3px]">
          <span
            className="text-yellow text-[20px]"
            style={{ opacity: playerAddress === NULL_ADDRESS ? 0 : 1 }}
          >
            {playerAddress.substring(0, 6)}......
            {playerAddress.substring(playerAddress.length - 4)}
          </span>
        </div>

        <div className="background-dark-background border border-border rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg px-[32px] py-[3px] border-t-0">
          <span className="text-gray text-[14px]">{t(playerName())}</span>
        </div>
        {renderGameResultImage()}
      </div>
      <img
        className="w-[80%] absolute top-[195px] left-1/2 transform -translate-x-1/2 -z-10"
        alt="player card"
        src={playerCardBg}
      />
      <AnimatedTransition show={showAnimation()}>
        {renderAnimation()}
      </AnimatedTransition>
      <AnimatedTransition
        show={
          amIHost &&
          !someoneElseGame &&
          roomStatus === RoomStatusEnum.Closed &&
          !isShowEndGameAnimation
        }
      >
        <GameFinishViews />
      </AnimatedTransition>
      <AnimatedTransition
        show={
          Boolean(
            amIHost &&
            activeRoomId &&
            playerB.address !== NULL_ADDRESS &&
            roomStatus !== RoomStatusEnum.Closed &&
            roomStatus !== RoomStatusEnum.Open
          ) || false

        }
        asFragment
      >
        <RockPaperScissors
          move={
            playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move
          }
          selectMove={selectMoveHandler}
        />
      </AnimatedTransition>
    </div>
  );
};
