import { Theme } from '@rainbow-me/rainbowkit';

export const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: 'none',
  },
  colors: {
    accentColor: '#FFA700',
    accentColorForeground: '#263238',
    actionButtonBorderMobile: '#FFA700',
    actionButtonSecondaryBackground: 'transparent',
    closeButton: '#01001D',
    closeButtonBackground: '#202066',
    connectButtonBackground: 'black',
    connectButtonBackgroundError: '#FFA700',
    connectButtonInnerBackground: '#FFA700',
    downloadBottomCardBackground: 'black',
    menuItemBackground: '#FFA700',
    modalBackdrop: 'none',
    modalBackground: 'none',
    modalBorder: '#202066',
    modalText: '#F7F7F7',
    modalTextDim: '#F7F7F7',
    modalTextSecondary: '#F7F7F7',
    profileAction: '#F7F7F7',
    actionButtonBorder: '',
    downloadTopCardBackground: '',
    error: '',
    generalBorder: '',
    generalBorderDim: '',
    connectionIndicator: '',
    profileActionHover: '',
    profileForeground: '',
    selectedOptionBorder: '',
    standby: '',
    connectButtonText: '',
    connectButtonTextError: '',
  },
  fonts: {
    body: 'Poppins',
  },
  radii: {
    actionButton: '8px',
    connectButton: '16px',
    menuButton: '10px',
    modal: '16px',
    modalMobile: '10px',
  },
  shadows: {
    connectButton: '...',
    dialog: '...',
    profileDetailsAction: '...',
    selectedOption: '...',
    selectedWallet: '...',
    walletLogo: '...',
  },
};
