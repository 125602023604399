import React, { InputHTMLAttributes, SetStateAction, Dispatch } from 'react';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useTranslation } from 'react-i18next';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value: string | number;
  valueHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  className?: string;
  balance?: number;
  disabled?: boolean;
}

const BetInput = ({
  value,
  valueHandler,
  type = 'text',
  className,
  balance,
  disabled,
  ...props
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`${className} ${
          disabled ? 'bg-gray' : 'bg-border'
        } flex items-end justify-between bg-border border-[1px] border-indigo text-white rounded-[7px] py-[8px] sm:py-[12px] px-[15px] sm:px-[22px] focus:outline-none focus:border-transparent 
        `}
      >
        <input
          className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-[80%] bg-transparent border-none text-white outline-none`}
          type="text"
          value={value}
          onChange={valueHandler}
          disabled={disabled}
          {...props}
        />
        <p className="text-[12px]  hidden sm:flex">
          {t('modals.setBet.yourBalance')}{' '}
          <AnimatedTransition show={Boolean((balance! ?? 0) >= 0)} asFragment>
            <span className="pl-1"> {balance}</span>
          </AnimatedTransition>
        </p>
      </div>
      <p className="text-[12px] text-white flex sm:hidden pt-2">
        {t('modals.setBet.yourBalance')}{' '}
        <AnimatedTransition show={Boolean((balance! ?? 0) >= 0)} asFragment>
          <span className="pl-1"> {balance}</span>
        </AnimatedTransition>
      </p>
    </>
  );
};

export default BetInput;
