import React, { useEffect } from 'react';
import { useSimulateContract, useWriteContract } from 'wagmi';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { WriteError } from 'shared/types/app-types';
import * as Sentry from '@sentry/react';

const spenderAddress = import.meta.env.VITE_RSP_CONTRACT_ADDRESS;

interface Props {
  debouncedBetInTokenAmount: number;
  finishApproveHandler: (approveState: {
    reject: boolean;
    error: boolean;
  }) => void;
  allowance?: bigint;
}

// use this if we want give max allowance, but need to be tested with increase
// let debouncedBetInTokenAmount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

const Approve = ({
                   debouncedBetInTokenAmount,
                   finishApproveHandler,
                   allowance = 0n,
                 }: Props) => {
  const { selectedCurrency } = useCurrenciesStore();
  const { setAllowanceHash, setAllowanceAmount, allowanceTransactions } =
    usePlayerGamesStore();
  // we simulate allowance to check if approve is successful, because some tokens have to be approved to 0 before approving a new amount
  const { isSuccess: simulateSuccess, isError: simulateError } =
    useSimulateContract({
      address: selectedCurrency?.address ?? NULL_ADDRESS,
      abi: [
        {
          constant: false,
          inputs: [
            { name: 'spender', type: 'address' },
            { name: 'amount', type: 'uint256' },
          ],
          name: 'approve',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ] as const,
      functionName: 'approve',
      args: [spenderAddress, BigInt(debouncedBetInTokenAmount)],
    });
  // we simulate increaseAllowance to check if we can just increase it and pay less for gas
  const { isSuccess: simulateIncreaseSuccess, isError: simulateIncreaseError, isFetching } =
    useSimulateContract({
      address: selectedCurrency?.address ?? NULL_ADDRESS,
      abi: [
        {
          constant: false,
          inputs: [
            { name: 'spender', type: 'address' },
            { name: 'amount', type: 'uint256' },
          ],
          name: 'increaseAllowance',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ] as const,
      functionName: 'increaseAllowance',
      args: [spenderAddress, BigInt(Number(debouncedBetInTokenAmount) - Number(allowance))],
    });

  const {
    writeContract: writeApproveAmount,
    isSuccess,
    data,
    isError,
    error,
    isPending,
  } = useWriteContract();
  const approveHandler = (amount: bigint, functionName: string) => {
    if (isPending) return;
    setAllowanceAmount(Number(amount));
    writeApproveAmount?.({
      address: selectedCurrency?.address ?? NULL_ADDRESS,
      abi: [
        {
          constant: false,
          inputs: [
            { name: 'spender', type: 'address' },
            { name: 'amount', type: 'uint256' },
          ],
          name: functionName,
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ] as const,
      functionName: functionName,
      args: [spenderAddress, amount],
    });
  };
  const {
    isSuccess: isSuccessApproveAmount,
    isError: isErrorApproveAmount,
    error: transactionError,
    limitExceed,
  } = useTransactionReceipt(data);
  useEffect(() => {
    if (simulateIncreaseSuccess) {
      approveHandler(BigInt(Number(debouncedBetInTokenAmount) - Number(allowance)), 'increaseAllowance');
    } else if (simulateSuccess && !isFetching) {
      approveHandler(BigInt(debouncedBetInTokenAmount), 'approve');
    }
  }, [simulateSuccess, simulateIncreaseSuccess, isFetching]);
  useEffect(() => {
    if (simulateError && !simulateIncreaseSuccess && !isFetching) {
      approveHandler(0n, 'approve');
    }
  }, [simulateError, simulateIncreaseSuccess, isFetching]);
  useEffect(() => {
    if (isSuccess) {
      setAllowanceHash(data);
    }
  }, [isSuccess, data]);
  useEffect(() => {
    if ((isError && error) || limitExceed) {
      Sentry.captureException(error);
      const typedError = error as WriteError | undefined;
      if (typedError?.cause?.code) {
        finishApproveHandler({
          reject: typedError.cause.code === 4001,
          error: typedError.cause.code !== 4001,
        });
      } else {
        finishApproveHandler({
          reject: false,
          error: true,
        });
      }
      setAllowanceHash(null);
    }
  }, [isError, error, limitExceed]);
  useEffect(() => {
    if (isErrorApproveAmount) {
      Sentry.captureException(transactionError);
      finishApproveHandler({
        reject: false,
        error: true,
      });
    }
  }, [isErrorApproveAmount, transactionError]);
  useEffect(() => {
    if (isSuccessApproveAmount) {
      if (allowanceTransactions.amount === 0) {
        approveHandler(BigInt(debouncedBetInTokenAmount), 'approve');
      } else {
        finishApproveHandler({ reject: false, error: false });
      }
      setAllowanceHash(null);
    }
  }, [isSuccessApproveAmount]);
  return null;
};
export default Approve;
