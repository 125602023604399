import React, { useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import checklist from 'assets/png/checklist.png';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { useAccount } from 'wagmi';
import { useSignMessage } from 'wagmi';
import { useGlobalStore } from 'shared/store/globalStore';

export const SignModal = forwardRef<HTMLDivElement>((props, ref) => {
  const { t } = useTranslation();
  const [date, setDate] = React.useState<Date>();
  const { address } = useAccount();
  const {
    data: signMessageData,
    error,
    signMessage,
    variables,
  } = useSignMessage();
  const { setJwt, setJwtValidUntil } = useGlobalStore();
  useEffect(() => {
    if (signMessageData) {
      setJwt({ address, jwt: signMessageData });
      setJwtValidUntil({
        address,
        jwtValidUntil: date?.getTime() ?? 0,
      });
    }
  }, [signMessageData, date]);
  const handleSignMessage = async () => {
    let date = new Date(Date.now() + 1000 * 60 * 60 * 24);
    try {
      signMessage({
        message: `address: ${address} \nvalid until: ${new Date(
          date
        ).toISOString()}`,
      });
      setDate(date);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <div
      ref={ref}
      className="absolute top-0 bottom-0 left-0 right-0 z-[3] bg-black bg-opacity-75 w-full h-full flex items-center justify-center"
    >
      <div className="bg-grape relative p-6 rounded-[6px] w-[360px] border-[3px] border-dark-blue overflow-hidden flex flex-col">
        <div
          className="absolute w-[613px] top-[-100px] left-[-180px] h-[356px]"
          style={{
            background: 'rgba(255, 167, 0, 0.25)',
            filter: 'blur(64px)',
          }}
        />
        <img
          src={checklist}
          alt="warning"
          className="w-[152px]  mb-[28px] z-[1000] mx-auto"
        />
        <TextWithLeftColorLine
          className="mb-6 z-[1000]"
          colorClass={'bg-yellow-dark'}
        >
          <h2 className="text-white text-bold text-[32px] leading-8 mb-4 ">
            {t('signModal.confirm')}{' '}
          </h2>
          <h2 className="text-yellow-dark text-bold text-[32px] leading-8 mb-6">
            {t('signModal.ownership')}
          </h2>
          <p className="text-white opacity-80 text-[14px]">
            {t('signModal.confirmText')}{' '}
            <span className="text-yellow-dark opacity-100">
              {address && address.slice(-4)}
            </span>
          </p>
        </TextWithLeftColorLine>
        <p className="text-white opacity-80 text-[14px] mb-6">
          {t('signModal.free')}
        </p>
        <PrimaryButton
          className="w-full"
          text={'common.confirm'}
          onClick={handleSignMessage}
        />
      </div>
    </div>
  );
});
