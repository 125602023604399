import React from 'react';
import rock from 'assets/animation/paper-rock-scissors/stone-min.png';
import paper from 'assets/animation/paper-rock-scissors/paper-min.png';
import scissors from 'assets/animation/paper-rock-scissors/scissors-min.png';
import './RockScissorsPaperLoader.scss';
import LoaderHoc from 'shared/components/Loaders/LoaderHoc/LoaderHoc';

interface Props {
  className?: string;
  messages?: string[];
}

const RockScissorsPaperLoader = ({ className, messages }: Props) => {
  return (
    <LoaderHoc className={className} messages={messages}>
      <div className="loader-container relative w-[165px] h-[165px] mb-[20px]">
        <img
          src={rock}
          alt="rock"
          className="absolute w-[65px] top-0 left-[30%]"
        />
        <img
          src={paper}
          alt="paper"
          className="absolute w-[73px] top-[50%] left-0"
        />
        <img
          src={scissors}
          alt="scissors"
          className="absolute w-[65px] right-0 top-[50%]"
        />
      </div>
    </LoaderHoc>
  );
};

export default RockScissorsPaperLoader;
