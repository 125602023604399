import React from 'react';
import ModalWithFullScreenBg from 'shared/components/Modal/ModalWithFullScreenBg';
import { ModalPositionEnum } from 'modules/Room/RoomModals/constants/modalEnums';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { creatingMessages } from 'shared/constants/text-loaders';

interface Props {
  open: boolean;
}

const ConnectingToPlayerModal = ({ open }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { chainId } = useAccount();
  const { joinHash } = usePlayerGamesStore();
  return (
    <>
      <AnimatedTransition show={!!joinHash[chainId!]?.hash}>
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={joinHash[chainId!]?.hash}
          messages={creatingMessages}
          selector=".player-card-host"
        />
      </AnimatedTransition>
      <AnimatedTransition show={!joinHash[chainId!]?.hash}>
        <ModalWithFullScreenBg
          key={isMobile ? 'ModalPositionEnum.CENTER' : 'ModalPositionEnum.LEFT'}
          open={open}
          position={
            isMobile ? ModalPositionEnum.CENTER : ModalPositionEnum.LEFT
          }
          className="light-shade"
        >
          <div className="w-full p-[20px] sm:p-[30px]">
            <AnimatedTransition show={!joinHash[chainId!]?.hash}>
              <TextWithLeftColorLine colorClass="bg-green">
                <p className="text-white text-justify text-[12px] sm:text-[17px] mb-[20px]">
                  {t('modals.connectingGame.connecting')}
                </p>
                <p className="text-white text-justify text-[12px] sm:text-[17px]">
                  {t('modals.connectingGame.approve')}
                </p>
              </TextWithLeftColorLine>
            </AnimatedTransition>
          </div>
        </ModalWithFullScreenBg>
      </AnimatedTransition>
    </>
  );
};

export default ConnectingToPlayerModal;
