import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

const PrimaryButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { className, text, onClick, disabled, ...rest } = props;
  const { t } = useTranslation();
  return (
    <button
      ref={ref}
      className={`rounded-[8px] ${className ? className : ''}
      ${
        disabled
          ? 'bg-gray'
          : 'bg-primary-btn active:bg-primary-active hover:bg-primary-btn-hover'
      }
      `}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <div
        className={`rounded-[8px] m-[1px] py-[7px] px-[15px] sm:px-[35px] ${
          disabled
            ? 'bg-primary-btn-inner-disabled text-disabled-button-gray-text'
            : 'bg-primary-btn-inner text-white'
        } font-semibold text-[14px] sm:text-[22px]`}
      >
        {t(text)}
      </div>
    </button>
  );
});

export default PrimaryButton;
