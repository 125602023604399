import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import WithTimerLogicModalHOC from '../../HOC/WithTimerLogicModalHoc';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import {
  technicalDefeatMessages,
  waitYourMoveMessages,
} from 'shared/constants/text-loaders';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { useParams } from 'react-router-dom';

const WaitYouApproveMoveModal = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [technicalDefeatError, setTechnicalDefeatError] = useState(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { chainId } = useAccount();
  const { closeHash, playMoves } = usePlayerGamesStore();
  const { roundCounter } = useRoomStore();
  const { address } = useAccount();
  const { activeRoomId } = useParams();

  return (
    <>
      <AnimatedTransition
        show={
          !!(
            closeHash[chainId!]?.hash ||
            playMoves[activeRoomId! + address! + chainId + roundCounter]
              ?.moveHash?.hash
          )
        }
      >
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={
            closeHash[chainId!]?.hash ??
            playMoves[activeRoomId! + address! + chainId + roundCounter]
              ?.moveHash?.hash
          }
          selector=".player-card-host"
          messages={
            !!closeHash[chainId!]?.hash
              ? technicalDefeatMessages
              : waitYourMoveMessages
          }
        />
      </AnimatedTransition>
      <AnimatedTransition
        show={
          !closeHash[chainId!]?.hash &&
          !playMoves[activeRoomId! + address! + chainId + roundCounter]
            ?.moveHash?.hash
        }
      >
        <WithTimerLogicModalHOC
          key={isMobile ? 'ModalPositionEnum.CENTER' : 'ModalPositionEnum.LEFT'}
          selector=".player-card-host"
          modalName="waitYourMove"
          setShowTimer={setShowTimer}
          setShowCloseButton={setShowCloseButton}
          setTechnicalDefeatError={setTechnicalDefeatError}
        >
          <AnimatedTransition
            show={!showTimer && !showCloseButton && !technicalDefeatError}
          >
            <TextWithLeftColorLine colorClass="bg-green">
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px]">
                {t('modals.approveYourMove.approve')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px]">
                {t('modals.approveYourMove.confirm')}
              </p>
            </TextWithLeftColorLine>
          </AnimatedTransition>
          <AnimatedTransition show={showTimer}>
            <TextWithLeftColorLine colorClass="bg-green">
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px]">
                {t('modals.approveYourMove.approve')}
              </p>
              <p className="text-white text-[12px] mb-[10px] sm:text-[16px]">
                {t('modals.approveYourMove.confirm')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px]">
                {t('modals.approveYourMove.remember')}
              </p>
            </TextWithLeftColorLine>
          </AnimatedTransition>
          <AnimatedTransition show={showCloseButton}>
            <TextWithLeftColorLine colorClass="bg-green">
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px]">
                {t('modals.approveYourMove.gameOver')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px]">
                {t('modals.approveYourMove.technicalDefeatExplanation')}
              </p>
            </TextWithLeftColorLine>
          </AnimatedTransition>
          <AnimatedTransition show={technicalDefeatError}>
            {technicalDefeatError.toString()}
            <TextWithLeftColorLine colorClass="bg-red">
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px]">
                {t('modals.approveYourMove.technicalDefeatError')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px]">
                {t('modals.approveYourMove.technicalDefeatErrorExplanation')}
              </p>
            </TextWithLeftColorLine>
          </AnimatedTransition>
        </WithTimerLogicModalHOC>
      </AnimatedTransition>
    </>
  );
};

export default WaitYouApproveMoveModal;
