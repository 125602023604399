import React from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useRoomStore } from 'shared/store/roomStore';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useParams } from 'react-router-dom';
import './MobileBank.scss';

const MobileBank = () => {
  const { t } = useTranslation();
  const { playerB, bet } = useRoomStore();
  const { selectedCurrency } = useCurrenciesStore();
  const twoPlayersInRoom = playerB.address !== NULL_ADDRESS;
  const { activeRoomId } = useParams();
  return (
    <div className="flex flex-col items-center">
      <h4 className="text-blue-text text-[10px] sm:text-[16px]">Bank</h4>
      <div
        className={`${
          activeRoomId
            ? twoPlayersInRoom
              ? 'bank-100-orange'
              : 'bank-50-orange'
            : 'bg-black bg-opacity-30'
        } flex justify-around border border-border rounded-[4px] px-[5px] h-[26px] sm:h-[40px] items-center`}
      >
        <AnimatedTransition show={!!activeRoomId} asFragment>
          <span className="font-medium text-[14px] sm:text-[28px] text-white mx-[5px]">{`${
            twoPlayersInRoom ? bet * 2 : bet
          } ${selectedCurrency?.symbol ?? ''}`}</span>
        </AnimatedTransition>
        <AnimatedTransition show={!activeRoomId} asFragment>
          <span className="text-[14px] sm:text-[28px] text-gray mx-[5px] uppercase">
            {t('common.empty')}
          </span>
        </AnimatedTransition>
      </div>
    </div>
  );
};

export default MobileBank;
