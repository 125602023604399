// Case-insensitive string comparison
export const compareLowerCase = (a: string, b: string): boolean =>
  a.toLowerCase() === b.toLowerCase();


/**
 * Generates a random salt of specified length using Web Crypto API
 * @param length Length of the salt in bytes (default: 32)
 * @returns Hex string representation of the salt
 */
export function generateSalt(length: number = 16): string {
  const randomBytes = new Uint8Array(length);
  window.crypto.getRandomValues(randomBytes);

  return Array.from(randomBytes)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
}

/**
 * Generates a single sub-salt from a master salt
 * @param masterSalt The original salt to derive from
 * @param index The index of the sub-salt to generate
 * @param subSaltLength Length of the sub-salt in bytes (default: 16)
 * @returns Hex string representation of the sub-salt
 */
export async function generateSubSalt(
  masterSalt: string,
  index: number,
  subSaltLength: number = 16
): Promise<string> {
  const encoder = new TextEncoder();

  // Convert master salt to key
  const masterKey = await window.crypto.subtle.importKey(
    "raw",
    encoder.encode(masterSalt),
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"]
  );

  // Generate unique data for the sub-salt
  const data = encoder.encode(index.toString());

  // Create HMAC signature
  const signature = await window.crypto.subtle.sign(
    "HMAC",
    masterKey,
    data
  );

  // Convert to hex string and take required length
  return Array.from(new Uint8Array(signature))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('')
    .slice(0, subSaltLength * 2);
}