import { ModalTypeEnum } from '../constants/modalEnums';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface State {
  modalState: Record<ModalTypeEnum, boolean>;
  gameBetModalState: {
    reject: boolean;
    error: boolean;
  };
  revealModalState: {
    error: boolean;
    reject: boolean;
  };
  playMoveModalState: {
    reject: boolean;
    error: boolean;
  };
  notEnoughModalState: {
    continue: boolean;
    zeroBalance: boolean;
  };
  setRevealModalStateError: ({
    reject,
    error,
  }: {
    reject: boolean;
    error: boolean;
  }) => void;
  setPlayMoveModalState: ({
    reject,
    error,
  }: {
    reject: boolean;
    error: boolean;
  }) => void;
  setModalState: (modalType: ModalTypeEnum, flag: boolean) => void;
  closeAllModals: () => void;
  resetStore: () => void;
  setNotEnoughModalContinue: (flag: boolean) => void;
  setNotEnoughModalZeroBalance: (flag: boolean) => void;
  setGameBetModalState: ({
    reject,
    error,
  }: {
    reject: boolean;
    error: boolean;
  }) => void;
}

const initialState: State = {
  modalState: {
    [ModalTypeEnum.SET_BET]: false,
    [ModalTypeEnum.NOT_ENOUGH_COINS]: false,
    [ModalTypeEnum.LOOKING_OPPONENT]: false,
    [ModalTypeEnum.NO_ONE_JOIN]: false,
    [ModalTypeEnum.CREATE_NEW_ROOM]: false,
    [ModalTypeEnum.CONNECT_TO_PLAYER]: false,
    [ModalTypeEnum.TAKE_MONEY_BACK]: false,
    [ModalTypeEnum.WAIT_YOUR_REVEAL]: false,
    [ModalTypeEnum.WAIT_OPPONENT_MOVE]: false,
    [ModalTypeEnum.WAIT_OPPONENT_REVEAL]: false,
    [ModalTypeEnum.WAIT_YOUR_APPROVE]: false,
    [ModalTypeEnum.WAIT_YOUR_MOVE]: false,
    [ModalTypeEnum.CONNECTING_TO_PLAYER]: false,
    [ModalTypeEnum.WATCH_GAME]: false,
    [ModalTypeEnum.JOIN_GAME]: false,
    [ModalTypeEnum.TRANSACTION_TAKE_TO_LONG]: false,
  },
  gameBetModalState: {
    reject: false,
    error: false,
  },
  playMoveModalState: {
    reject: false,
    error: false,
  },
  revealModalState: {
    error: false,
    reject: false,
  },
  notEnoughModalState: {
    continue: false,
    zeroBalance: true,
  },
  setRevealModalStateError: () => {},
  setPlayMoveModalState: () => {},
  setModalState: () => {},
  closeAllModals: () => {},
  resetStore: () => {},
  setNotEnoughModalContinue: () => {},
  setNotEnoughModalZeroBalance: () => {},
  setGameBetModalState: () => {},
};

export const useModalStore = create<State>()(
  immer(
    devtools((set) => ({
      ...initialState,
      setModalState: (modalType, flag) =>
        set((state) => ({
          modalState: {
            ...state.modalState,
            [modalType]: flag,
          },
        })),
      setGameBetModalState: ({ reject, error }) =>
        set(() => ({
          gameBetModalState: {
            reject,
            error,
          },
        })),
      closeAllModals: () =>
        set((state) => {
          for (const prop in state.modalState) {
            state.modalState[prop as unknown as ModalTypeEnum] = false;
          }
          return state;
        }),
      setRevealModalStateError: ({ error, reject }) =>
        set((state) => ({
          revealModalState: {
            ...state.revealModalState,
            error,
            reject,
          },
        })),
      setPlayMoveModalState: ({ error, reject }) =>
        set(() => ({
          playMoveModalState: {
            error,
            reject,
          },
        })),
      setNotEnoughModalContinue: (continueFlag) =>
        set((state) => ({
          notEnoughModalState: {
            ...state.notEnoughModalState,
            continue: continueFlag,
          },
        })),
      setNotEnoughModalZeroBalance: (zeroBalanceFlag) =>
        set((state) => ({
          notEnoughModalState: {
            ...state.notEnoughModalState,
            zeroBalance: zeroBalanceFlag,
          },
        })),
      resetStore: () => set(initialState),
    }))
  )
);
