import { CHAINS_LOGOS } from 'shared/config';
import React from 'react';

interface Props {
  chainId: number;
  className?: string;
}

const IconChain = ({ chainId, className }: Props) => {
  const Component = CHAINS_LOGOS[chainId as keyof typeof CHAINS_LOGOS];
  return <div className={`${className ? className : ''}`}>{Component}</div>;
};

export default IconChain;