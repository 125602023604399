import { useReadContract } from 'wagmi';
import { erc20Abi } from 'viem';

interface Props {
  tokenAddress: `0x${string}`;
  spenderAddress: `0x${string}`;
  ownerAddress: `0x${string}`;
}

export const useAllowance = ({
  tokenAddress,
  spenderAddress,
  ownerAddress,
}: Props) => {
  return useReadContract({
    address: tokenAddress,
    abi: erc20Abi,
    functionName: 'allowance',
    args: [ownerAddress, spenderAddress],
    query: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  });
};
