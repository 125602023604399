import React, { useEffect, useState } from 'react';
import Allowance from 'modules/Room/features/Allowance/Allowance';
import { useTranslation } from 'react-i18next';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import Select from 'shared/components/Select/Select';
import ModalWithFullScreenBg from 'shared/components/Modal/ModalWithFullScreenBg';
import { ModalPositionEnum } from 'modules/Room/RoomModals/constants/modalEnums';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import BetInput from 'shared/components/BetInput/BetInput';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useBalance } from 'shared/hooks/useBalance';
import { useAccount } from 'wagmi';
import { useRoomStore } from 'shared/store/roomStore';
import { useParams } from 'react-router-dom';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import GuysLoader from 'shared/components/Loaders/GuysLoader/GuysLoader';
import { allowanceMessages } from 'shared/constants/text-loaders';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';

interface Props {
  open: boolean;
}

const JoinGameModal = ({ open }: Props) => {
  const { t } = useTranslation();
  const [bet, setBet] = useState(0);
  const [betIsValid, setBetIsValid] = useState(true);
  const [startStep, setStartStep] = useState(false);
  const [allowancePending, setAllowancePending] = useState(false);
  const [allowanceFetching, setAllowanceFetching] = useState(false);
  const { address } = useAccount();
  const { currencyForSelect, selectedCurrency } = useCurrenciesStore();
  const { bet: roomBet } = useRoomStore();
  const { allowanceTransactions } = usePlayerGamesStore();

  useEffect(() => {
    if (roomBet) setBet(roomBet);
  }, [roomBet]);
  const { balance } = useBalance({
    currencyAddress: selectedCurrency!.address,
    walletAddress: address as `0x${string}`,
  });

  const isMobile = useMediaQuery('(max-width: 1200px)');
  if (!selectedCurrency) return null;
  return (
    <ModalWithFullScreenBg
      key={isMobile ? 'ModalPositionEnum.CENTER' : 'ModalPositionEnum.LEFT'}
      open={open}
      position={isMobile ? ModalPositionEnum.CENTER : ModalPositionEnum.LEFT}
      showFrameLoader={allowanceFetching}
    >
      <div className="sm:w-full p-[20px] sm:p-[30px] relative">
        <AnimatedTransition show={allowancePending}>
          <GuysLoader
            hash={allowanceTransactions.hash}
            messages={allowanceMessages}
            className="absolute top-0 left-0 rounded-[4px] sm:rounded-[14px] w-full h-full bg-[#000014D9] bg-opacity-85 z-50"
          />
        </AnimatedTransition>
        <TextWithLeftColorLine colorClass="bg-green" className="mb-[25px]">
          {startStep ? (
            <>
              <p className="text-white text-[12px] sm:text-[17px] mb-[10px]">
                {t('modals.joinGameModal.start')}
              </p>
              <p className="text-white text-[12px] sm:text-[17px]">
                {t('modals.joinGameModal.pressStart')}
              </p>
            </>
          ) : (
            <>
              <p className="text-white text-[12px] sm:text-[17px] mb-[10px]">
                {t('modals.joinGameModal.authorize')}
              </p>
              <p className="text-white text-[12px] sm:text-[17px]">
                {t('modals.joinGameModal.pressAuthorize')}
              </p>
            </>
          )}
        </TextWithLeftColorLine>

        <div className="flex w-full mb-[25px] items-start justify-between">
          <div className="w-[70%] sm:flex-grow flex flex-col mr-[10px] sm:mr-[22px]">
            <BetInput
              value={bet.toString()}
              valueHandler={() => {}}
              type="number"
              className="sm:flex-grow"
              balance={balance ?? 0}
              disabled
            />
          </div>

          <Select
            className="flex-grow"
            options={currencyForSelect}
            selectedOption={{
              value: selectedCurrency.symbol,
              label: selectedCurrency.symbol,
            }}
            onSelect={() => {}}
            disabled
          />
        </div>
        <Allowance
          setStartStep={setStartStep}
          joinGameModal={true}
          balance={balance ?? 0}
          preferredBet={bet}
          betIsValid={betIsValid}
          setAllowancePending={setAllowancePending}
          setAllowanceFetching={setAllowanceFetching}
        />
      </div>
    </ModalWithFullScreenBg>
  );
};

export default JoinGameModal;
