import { FC, useMemo } from 'react';
import { useRoomStore } from 'shared/store/roomStore';
import { renderIcon } from 'shared/components/HistoryIcon/renderIconByMove';
import { RoundWinnerOutcomes } from 'shared/constants/shared-enums';

interface Props {
  round: {
    moveA: number;
    moveB: number;
    winner: RoundWinnerOutcomes;
  };
  gameCount: number;
  lastRound: boolean;
  visible: boolean;
  dataIndex: number;
}

enum bgColor {
  default,
  win,
  lose,
}

const MobileHistoryMove: FC<Props> = ({
  round,
  gameCount,
  lastRound,
  visible,
  dataIndex,
}) => {
  const { amIPlayerA } = useRoomStore();
  let bgColorClass = bgColor.default;
  if (lastRound) {
    if (round.winner === RoundWinnerOutcomes.PlayerA && amIPlayerA)
      bgColorClass = bgColor.win;
    if (round.winner === RoundWinnerOutcomes.PlayerB && !amIPlayerA)
      bgColorClass = bgColor.win;
    if (round.winner === RoundWinnerOutcomes.PlayerA && !amIPlayerA)
      bgColorClass = bgColor.lose;
    if (round.winner === RoundWinnerOutcomes.PlayerB && amIPlayerA)
      bgColorClass = bgColor.lose;
  }
  const playerAMoveIcon = useMemo(
    () => renderIcon(round.moveA, round.winner === RoundWinnerOutcomes.PlayerA),
    [round]
  );

  const playerBMoveIcon = useMemo(
    () => renderIcon(round.moveB, round.winner === RoundWinnerOutcomes.PlayerB),
    [round]
  );

  const playerAClass = amIPlayerA ? 'order-1' : 'order-3';

  const playerBClass = amIPlayerA ? 'order-3' : 'order-1';

  if (round.winner === RoundWinnerOutcomes.None) return null;
  return (
    <div
      className={`flex items-center flex-col w-full history-move 
    ${visible ? 'active' : 'passive'}
    `}
      data-index={dataIndex}
      data-testid="history-move-container"
    >
      <div
        className={`w-full h-[1px]  ${
          bgColorClass === bgColor.default ? 'bg-history-border' : ''
        }
           ${bgColorClass === bgColor.win ? 'bg-history-border-win' : ''}
           ${bgColorClass === bgColor.lose ? 'bg-history-border-lose' : ''}
      `}
      />
      <div
        className={`flex justify-between sm:justify-center px-[60px] items-center h-[45px] sm:h-[65px] w-full ${
          bgColorClass === bgColor.default ? 'bg-history-content' : ''
        }
           ${bgColorClass === bgColor.win ? 'bg-history-content-win' : ''}
           ${bgColorClass === bgColor.lose ? 'bg-history-content-lose' : ''}
         `}
      >
        <div
          className={`flex w-[33%] sm:w-[20%] justify-center ${playerAClass}`}
        >
          {playerAMoveIcon}
        </div>
        <span className="order-2 w-[33%] sm:w-[20%] flex justify-center text-[21px] font-medium text-white">
          {gameCount}
        </span>
        <div
          className={`flex w-[33%] sm:w-[20%] justify-center ${playerBClass}`}
        >
          {playerBMoveIcon}
        </div>
      </div>
      {lastRound && (
        <div
          className={`w-full h-[1px]  ${
            bgColorClass === bgColor.default ? 'bg-history-border' : ''
          }
           ${bgColorClass === bgColor.win ? 'bg-history-border-win' : ''}
           ${bgColorClass === bgColor.lose ? 'bg-history-border-lose' : ''}
       `}
        />
      )}
    </div>
  );
};

export default MobileHistoryMove;
