import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import CloseXIcon from 'assets/close-x.svg?react';
import CopyIcon from 'assets/copy3.svg?react';
import { useTranslation } from 'react-i18next';
import AboutIcon from 'assets/about.svg?react';
import ReferralIcon from 'assets/referral.svg?react';

import LogoutIcon from 'assets/logout.svg?react';
import { Transition } from '@headlessui/react';
import { useAccount, useDisconnect } from 'wagmi';
import { useRoomStore } from 'shared/store/roomStore';
import { useParams } from 'react-router-dom';
import { RoomStatusEnum } from 'shared/constants/shared-enums';

interface OverlayProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Overlay = ({ open, setOpen }: OverlayProps) => {
  const { t } = useTranslation();
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { setShowHash, someoneElseGame } = useRoomStore();
  const { activeRoomId } = useParams();
  const showHandler = () => {
    setOpen(!open);
    setShowHash(true);
  };
  const { roomStatus } = useRoomStore();
  return createPortal(
    <Transition
      show={open}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as={Fragment}
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-[9999]"
        onClick={() => setOpen(false)}
      >
        <Transition.Child
          enter="transition ease-in-out duration-500 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          as={Fragment}
        >
          <div className="w-[70%] h-[100%] ml-[30%] bg-dark-background pt-[11px] px-[25px]">
            <button
              className="put this button right"
              onClick={() => setOpen(false)}
            >
              <CloseXIcon className="w-[14px] h-[14px]" />
            </button>

            <ul className={'mt-[34px]'}>
              <li>
                <a
                  className={`flex items-center text-white text-[16px] font-semibold`}
                  href="https://rock-paper-scissors.game/rules"
                  target="_blank"
                  onClick={() => setOpen(false)}
                >
                  <AboutIcon className={'mr-[10px] w-[20px] h-[20px] mb-1'} />
                  {t('pages.about')}
                </a>
              </li>
              <li className="mt-[37px]">
                <a
                  className={`flex items-center text-white text-[16px] font-semibold`}
                  href="https://rock-paper-scissors.game/referral"
                  target="_blank"
                  onClick={() => setOpen(false)}
                >
                  <ReferralIcon
                    className={'mr-[10px] w-[20px] h-[20px] mb-1'}
                  />
                  {t('pages.referral')}
                </a>
              </li>
              {activeRoomId && !someoneElseGame &&
                roomStatus !== RoomStatusEnum.Closed && (
                <li className="mt-[37px]">
                  <div
                    className={`flex items-center text-white text-[16px] font-semibold`}
                    onClick={showHandler}
                  >
                    <CopyIcon className={'mr-[10px] w-[20px] h-[20px] mb-1'} />
                    {t('hash.show')}
                  </div>
                </li>
              )}
              {isConnected && (
                <li className="mt-[37px]">
                  <button
                    className={`flex items-center text-white text-[16px] font-semibold`}
                    onClick={() => {
                      setOpen(false);
                      disconnect();
                    }}
                  >
                    <LogoutIcon className="mr-[10px] w-[20px] h-[20px] mb-1" />
                    {t('common.disconnect')}
                  </button>
                </li>
              )}
            </ul>
          </div>
        </Transition.Child>
      </div>
    </Transition>,
    document.body
  );
};
export default Overlay;
