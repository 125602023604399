import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { useRoomStore } from 'shared/store/roomStore';
import { generateSalt } from 'shared/constants/utils';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useParams } from 'react-router-dom';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { useHash } from 'shared/hooks/useHash';
import { useGlobalStore } from 'shared/store/globalStore';

export const useSaltGenerator = () => {
  const { address, chainId } = useAccount();
  const { playerA, playerB, roomStatus } = useRoomStore();
  const { activeRoomId } = useParams();
  const { mainSalt, setMainSalt } = usePlayerGamesStore();
  const { handleSetHash, handleGetHash } = useHash();
  const { jwt } = useGlobalStore();
  const saltCreator = async () => {
    let mainSaltValue;
    if (
      !!(address &&
      (playerA.address === address.toLowerCase() ||
        playerB.address === address.toLowerCase()) &&
      !mainSalt?.[activeRoomId! + address! + chainId] &&
      roomStatus !== RoomStatusEnum.Closed &&
      jwt[address!])
    ) {
      mainSaltValue = await handleGetHash({
        hashKey: activeRoomId! + address! + chainId,
      });
      if (!mainSaltValue) {
        const newSalt = generateSalt();
        setMainSalt(activeRoomId! + address! + chainId, newSalt);
        handleSetHash({
          hashValue: newSalt,
          hashKey: activeRoomId! + address! + chainId,
        });
      } else {
        setMainSalt(activeRoomId! + address! + chainId, mainSaltValue);
      }
    }
  };
  useEffect(() => {
    if (activeRoomId) {
      saltCreator();
    }
  }, [activeRoomId, jwt]);
};
