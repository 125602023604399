import React, { forwardRef } from 'react';
import GridBackground from 'shared/components/GridBackground/GridBackground';
import './Modal.scss';

interface Props {
  children: React.ReactNode;
  classNames?: string;
}

const Modal = forwardRef<HTMLDivElement, Props>(
  ({ children, classNames }: Props, ref) => {
    return (
      <div
        ref={ref}
        className={`absolute border-[2px] sm:border-[3px] border-border rounded-[4px] sm:rounded-[14px] z-40 top-[360px] sm:top-[450px] lg:top-1/2 transform lg:-translate-y-1/2 ${classNames}`}
      >
        <GridBackground className="rounded-[10px]">
          <div className="p-[20px] sm:p-[30px]">{children}</div>
        </GridBackground>
      </div>
    );
  }
);

export default Modal;
