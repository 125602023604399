import { encodeFunctionData } from 'viem';
import contractAbi from 'shared/contract/contract.abi.json';
import { config } from 'shared/config';
import { estimateGas } from '@wagmi/core';

export const estimateGasForFunction = async (
  functionName: string,
  args: any[]
): Promise<bigint> => {
  const data = encodeFunctionData({
    abi: contractAbi,
    functionName,
    args,
  });
  const estimatedGas = await estimateGas(config, {
    to: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
    data,
  });
  const gasWithBuffer = (estimatedGas * 12n) / 10n;
  return gasWithBuffer ?? 6000000n;
};
