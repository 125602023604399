export function calculateTimeWithDelay(time: number) {
  const now = new Date();
  now.setMilliseconds(now.getMilliseconds() + time);
  return now;
}

export const epochToLocalUTC = (epoch: bigint): Date => {
  const d = new Date(0);
  d.setUTCSeconds(Number(epoch));
  return d;
};
