import React, { useState } from 'react';
import { ModalPositionEnum } from 'modules/Room/RoomModals/constants/modalEnums';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import { useTranslation } from 'react-i18next';
import WithTimerLogicModalHOC from '../../HOC/WithTimerLogicModalHoc';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { technicalDefeatMessages } from 'shared/constants/text-loaders';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';

const WaitOpponentRevealModal = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [technicalDefeatError, setTechnicalDefeatError] = useState(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { chainId } = useAccount();
  const { closeHash } = usePlayerGamesStore();
  return (
    <>
      <AnimatedTransition show={!!closeHash[chainId!]?.hash}>
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={closeHash[chainId!]?.hash}
          selector=".player-card-opponent"
          messages={technicalDefeatMessages}
        />
      </AnimatedTransition>
      <AnimatedTransition show={!closeHash[chainId!]?.hash}>
        <WithTimerLogicModalHOC
          key={
            isMobile ? 'ModalPositionEnum.CENTER' : 'ModalPositionEnum.RIGHT'
          }
          selector={isMobile ? '.player-card-host' : '.player-card-opponent'}
          modalName="waitOpponentReveal"
          setShowTimer={setShowTimer}
          setShowCloseButton={setShowCloseButton}
          setTechnicalDefeatError={setTechnicalDefeatError}
        >
          <AnimatedTransition
            show={!showTimer && !showCloseButton && !technicalDefeatError}
          >
            <TextWithLeftColorLine colorClass="bg-green">
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
                {t('modals.waitOpponentReveal.youMadeMove')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px]  text-justify">
                {t('modals.waitOpponentReveal.waiting')}
              </p>
            </TextWithLeftColorLine>
          </AnimatedTransition>
          <AnimatedTransition show={showTimer}>
            <TextWithLeftColorLine colorClass="bg-green">
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
                {t('modals.waitOpponentReveal.youMadeMove')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
                {t('modals.waitOpponentReveal.waiting')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px] text-justify">
                {t('modals.waitOpponentReveal.patience')}
              </p>
            </TextWithLeftColorLine>
          </AnimatedTransition>
          <AnimatedTransition show={showCloseButton}>
            <TextWithLeftColorLine colorClass="bg-green">
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
                {t('modals.waitOpponentReveal.gameOver')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px] text-justify">
                {t('modals.waitOpponentReveal.technicalDefeatExplanation')}
              </p>
            </TextWithLeftColorLine>
          </AnimatedTransition>
          <AnimatedTransition show={technicalDefeatError}>
            <TextWithLeftColorLine colorClass="bg-red">
              <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
                {t('modals.waitOpponentReveal.techDefeatError')}
              </p>
              <p className="text-white text-[12px] sm:text-[16px] text-justify">
                {t('modals.waitOpponentReveal.techDefeatErrorExplanation')}
              </p>
            </TextWithLeftColorLine>
          </AnimatedTransition>
        </WithTimerLogicModalHOC>
      </AnimatedTransition>
    </>
  );
};

export default WaitOpponentRevealModal;
